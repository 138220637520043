define("ember-svg-jar/inlined/icon-currency-EUR", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"10\" cy=\"10\" r=\"10\" fill=\"#aaa9a9\"/><g fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M11.975 6.945c-.48-.419-1.061-.668-1.689-.668-1.639 0-2.967 1.667-2.967 3.723s1.328 3.723 2.967 3.723c.629 0 1.21-.249 1.689-.668M6.025 8.947h4.304M6.025 11.053h4.304\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});