define("ember-svg-jar/inlined/dismiss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9D9FA2\" d=\"M8.701.125L5.623 3.198 2.548.125.125 2.548l3.077 3.074L.125 8.694l2.423 2.426 3.075-3.071 3.078 3.071 2.424-2.426-3.077-3.072 3.077-3.074z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "11.25",
      "height": "11.245",
      "viewBox": "0 0 11.25 11.245"
    }
  };
  _exports.default = _default;
});