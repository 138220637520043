define("ember-svg-jar/inlined/twitter-quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"91.7\" cy=\"34.2\" r=\"15.2\" fill=\"#439cd6\" transform=\"translate(-76.5 -19)\"/><path d=\"M12.5 23.2c6.7 0 10.4-5.6 10.4-10.4v-.5c.7-.5 1.3-1.2 1.8-1.9-.7.3-1.4.5-2.1.6.8-.5 1.3-1.2 1.6-2-.7.4-1.5.7-2.3.9-.7-.7-1.6-1.2-2.7-1.2-2 0-3.7 1.6-3.7 3.7 0 .3 0 .6.1.8-3-.2-5.7-1.6-7.5-3.8-.3.5-.5 1.2-.5 1.8 0 1.3.6 2.4 1.6 3-.6 0-1.2-.2-1.7-.5 0 1.8 1.3 3.2 2.9 3.6-.3.1-.6.1-1 .1-.2 0-.5 0-.7-.1.5 1.5 1.8 2.5 3.4 2.5-1.3 1-2.8 1.6-4.5 1.6-.3 0-.6 0-.9-.1 1.8 1.3 3.7 1.9 5.8 1.9\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 30.4 30.4",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});