define("ember-svg-jar/inlined/404-hills-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<image overflow=\"visible\" opacity=\".1\" width=\"1333\" height=\"602\" xlink:href=\"392229F0603DFEAA.png\" transform=\"matrix(.24 0 0 .24 -12.614 -12.614)\"/><path fill=\"#BBCAC6\" d=\"M294.665 109.152l-66.472-69.106h-18.688L177.47 0H117.4L0 109.152v10.008h294.665z\"/><path fill=\"#CAD8D7\" d=\"M263.641 77.142l-35.448-37.096h-18.688L177.47 0H117.4l-8.82 8.013h60.874L201.49 48.06h19.689l34.446 37.098-1.001 23.994h9.017z\"/><image overflow=\"visible\" opacity=\".1\" width=\"867\" height=\"309\" xlink:href=\"392229F0603DFEAE.png\" transform=\"matrix(.24 0 0 .24 189.22 51.539)\"/><path fill=\"#BBCAC6\" d=\"M195.699 109.152l86.197-51.133h44.964l23.981 29.977h13.989l25.98 21.156v10.008H195.699z\"/><path fill=\"#B4C0BF\" d=\"M233.388 109.152h-23.197l57.542-36.972h23.197z\"/><path fill=\"#CAD8D7\" d=\"M323.482 63.788l23.377 29.97h16.184l18.581 15.394h9.146l-25.94-21.156h-13.989L326.86 58.019h-44.964l-9.75 5.769z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "397.299",
      "height": "119.108",
      "viewBox": "0 0 397.299 119.108"
    }
  };
  _exports.default = _default;
});