define("ember-prism/components/code-inline", ["exports", "ember-prism/components/code-base"], function (_exports, _codeBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _codeBase.default.extend({
    tagName: 'code',
    classNames: ['code-inline'],
    inline: true
  });

  _exports.default = _default;
});