define("ember-svg-jar/inlined/icon-downloadlogs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"icon-downloadlogs_svg__st0\" d=\"M4.996 1.5v10.263m4.496-3.416l-4.496 4.496L.5 8.347m7.423-6.052H14.5M7.923 5.547H14.5M12.043 8.8H14.5m-5.276 3.252H14.5\"/>",
    "attrs": {
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});