define("ember-svg-jar/inlined/icon-linux", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"icon-linux_svg__st0\" d=\"M12.875 9.231c-.001-.612-1.256-2.546-2.238-3.51-.727-.713-.868-3.022-.868-3.022a2.33 2.33 0 00-4.653 0c-.168 1.05-.134 2.286-.868 3.022-.971.975-2.13 2.86-2.238 3.51l-.008.06c-.024.296.133.552.362.584a.38.38 0 00.292-.091c.022-.019.074-.072.091-.091.074-.08.919-.429 1.277-.58.27 1.987 1.357 3.641 3.418 3.641s3.254-1.689 3.524-3.676c.359.151 1.097.535 1.171.615.017.019.069.072.092.091a.38.38 0 00.292.091c.229-.032.354-.229.354-.644z\"/><path class=\"icon-linux_svg__st0\" d=\"M4.154 9.889c0-1.741 1.641-3.986 3.385-3.986s3.384 1.852 3.384 3.593\"/><path class=\"icon-linux_svg__st1\" d=\"M8.595 3.766a.192.192 0 00-.089-.173c-.12-.079-.463-.26-1.056-.26-.604 0-.951.171-1.066.241-.03.017-.104.062-.104.192a.19.19 0 00.079.157l.996.669s.042.03.082.03c.029 0 .082-.026.082-.026s.996-.662 1.035-.71a.19.19 0 00.041-.12zm3.002 8.683a.5.5 0 00-.385-.487.502.502 0 01-.446-.497c0-.02.004-.093.001-.127a.503.503 0 00-.855-.309c-.017.017-.082.088-.1.101a.501.501 0 01-.515.054.933.933 0 01-.07-.046.504.504 0 00-.749.243c-.02.052-.088.351-.088 1.295 0 .673.087 1.421.411 1.655 0 0 .088.078.188.117a.915.915 0 00.292.052c1.049 0 2.208-1.729 2.256-1.814a.485.485 0 00.06-.237zm-8.319 0a.5.5 0 01.385-.487.502.502 0 00.446-.497c0-.02-.004-.093-.001-.127a.503.503 0 01.855-.309c.017.017.082.088.1.101a.501.501 0 00.515.054.933.933 0 00.07-.046.504.504 0 01.749.243c.02.052.088.351.088 1.295 0 .673-.087 1.421-.411 1.655 0 0-.088.078-.188.117a.915.915 0 01-.292.052c-1.049 0-2.208-1.729-2.256-1.814a.485.485 0 01-.06-.237z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});