define("ember-svg-jar/inlined/features-callouts-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#B8BABC\" d=\"M24.384 2.479c0-.229-.016-.461-.065-.681C24.033.616 23.199.002 21.855.002 15.448-.001 9.032 0 2.624 0 .901 0 .02.883.02 2.603v12.786c0 4.283.03 8.562-.02 12.845-.012 1.348.973 2.529 2.481 2.514 6.484-.053 12.966-.02 19.448-.021 1.553 0 2.456-.898 2.457-2.459.004-8.597.002-17.195-.002-25.789zM8.578 23.919c-1.042 1.02-2.062 2.064-3.094 3.088-.091.092-.172.273-.336.18-.118-.064-.073-.223-.074-.34-.003-1.092-.002-2.184-.002-3.268h.017c0-1.092-.001-2.182 0-3.268 0-.119-.06-.271.081-.33.138-.061.215.092.294.174 1.042 1.025 2.067 2.053 3.112 3.072.248.247.274.427.002.692zm9.995 3.461c-.757-.029-1.517-.01-2.274-.012-.768-.002-.77-.002-.77-.75v-9.354c0-.985-.016-1.97.005-2.958.007-.535-.255-.819-.718-.969-.601-.199-1.222-.166-1.832-.082-2.519.312-4.888 1.183-7.251 2.049-.656.242-.682.234-.683-.454-.006-3.64.003-7.288-.017-10.929-.003-.462.159-.573.576-.572.865.021 1.73.021 2.59-.006.468-.02.604.166.601.619a692.145 692.145 0 00-.01 6.445c.001.691.003.688.687.475 1.788-.568 3.603-.963 5.496-.883.896.04 1.754.228 2.521.697 1.159.708 1.625 1.832 1.65 3.131.031 2.146.007 4.293.007 6.442h.019c0 2.168-.01 4.336.006 6.498-.001.451-.137.633-.603.613zm1.786-23.502a21.347 21.347 0 01-2.456 3.992c-.161.203-.349.271-.591.267-.511-.009-1.021-.003-1.531-.002-.533 0-1.057-.004-1.59.002-.301.003-.463-.057-.229-.379.924-1.274 1.662-2.655 2.315-4.077.112-.252.3-.323.55-.323 1.072.004 2.145.009 3.217-.002.42-.003.481.166.315.522z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24.388",
      "height": "30.749",
      "viewBox": "0 0 24.388 30.749"
    }
  };
  _exports.default = _default;
});