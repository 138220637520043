define("ember-svg-jar/inlined/icon-credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.657 13.144a.858.858 0 01-.856.856H.856A.858.858 0 010 13.144V.856C0 .385.385 0 .856 0h18.945c.471 0 .856.385.856.856v12.288z\" fill=\"#aaa9a9\"/><path fill=\"#514f50\" d=\"M0 2.194h20.657v2.515H0z\"/><path fill=\"#fff\" d=\"M1.391 5.887h17.874v2.071H1.391z\"/><path d=\"M4.362 11.645a.43.43 0 01-.428.428H1.82a.43.43 0 01-.428-.428V10.43a.43.43 0 01.428-.428h2.115a.43.43 0 01.428.428v1.215z\" fill=\"#d9d7d7\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20.657 14"
    }
  };
  _exports.default = _default;
});