define("ember-prism/components/code-block", ["exports", "ember-prism/components/code-base", "ember-prism/templates/components/code-block"], function (_exports, _codeBase, _codeBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _codeBase.default.extend({
    layout: _codeBlock.default,
    tagName: 'pre',
    classNames: ['code-block'],
    getElement: function getElement() {
      return this.element.querySelector('[class*=language-]');
    }
  });

  _exports.default = _default;
});