define("ember-svg-jar/inlined/msg-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" stroke=\"#db4545\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M4.5 4.3l9.6 9.5M14.1 4.3l-9.6 9.5\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 18.6 18"
    }
  };
  _exports.default = _default;
});