define("ember-svg-jar/inlined/icon-canceled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"icon-canceled_svg__st0\" d=\"M2.542 2.938l9.764 9.613\"/><circle class=\"icon-canceled_svg__st0\" cx=\"7.5\" cy=\"7.5\" r=\"7\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});