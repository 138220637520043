define("ember-svg-jar/inlined/features-callouts-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#B8BABC\"><path d=\"M26.16 12.392c-2.185 6.103-4.356 12.172-6.568 18.356l-6.57-18.356H26.16zM18.3 30.604A104784.21 104784.21 0 01.051 12.353h1.651c3.001 0 6.005.003 9.008-.003.259 0 .429.036.542.327 2.325 5.921 4.663 11.835 6.998 17.75.017.045.025.094.05.177zM38.684 12.355L21.256 30.627l-.053-.033c.43-1.137.855-2.275 1.285-3.414 1.809-4.81 3.623-9.618 5.424-14.433.111-.298.253-.396.567-.393 3.191.009 6.384.002 9.576 0l.629.001zM25.132 10.77H13.035c2.026-3.096 4.021-6.144 6.049-9.239l6.048 9.239zM28.09 10.771L32.461.687c2.09 3.387 4.139 6.709 6.219 10.084H28.09zM26.698 10.106A20565.72 20565.72 0 0020.14.016h10.427c-1.292 3.366-2.568 6.697-3.869 10.09zM7.932 0h10.445c-2.314 3.382-4.588 6.711-6.908 10.106L7.932 0zM0 10.77L6.719.688l3.699 10.082H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "38.684",
      "height": "30.748",
      "viewBox": "0 0 38.684 30.748"
    }
  };
  _exports.default = _default;
});