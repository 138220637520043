define("ember-prism/components/code-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['languageClass'],
    inline: false,
    language: 'markup',
    code: null,
    hasBlock: Ember.computed.empty('code'),
    languageClass: Ember.computed('language', function () {
      return "language-".concat(this.get('language'));
    }),
    getBlockContent: function getBlockContent() {
      return this.blockElement && this.blockElement.textContent;
    },
    prismCode: Ember.computed('code', function () {
      var code = this.get('hasBlock') ? this.getBlockContent() : this.get('code');
      var language = this.get('language');
      if (!code) return '';
      var grammar = Prism.languages[language];
      if (!grammar) return '';
      var prismCode = Prism.highlight(code, grammar, language);
      return Ember.String.htmlSafe(prismCode);
    }),
    getElement: function getElement() {
      return this.element;
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (typeof document !== 'undefined') {
        this.blockElement = document.createElement('div');
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var code = this.get('code');

      if (this.get('hasBlock')) {
        code = this.getBlockContent(); // if block content has changed, force reevaluation of `prismCode`

        if (code !== this._lastBlockContent) {
          this._lastBlockContent = code;
          this.notifyPropertyChange('prismCode');
        }
      } // force plugin initialization, required for Prism.highlight usage, see https://github.com/PrismJS/prism/issues/1234


      Prism.hooks.run('complete', {
        code: code,
        element: this.getElement()
      });
    }
  });

  _exports.default = _default;
});