define("ember-svg-jar/inlined/signingithub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#A0A9A9\" d=\"M7.998 0a8 8 0 00-2.525 15.59c.396.074.545-.172.545-.385 0-.189-.01-.693-.01-1.359-2.227.482-2.697-1.074-2.697-1.074-.365-.925-.889-1.17-.889-1.17-.727-.496.055-.486.055-.486.805.057 1.227.825 1.227.825.711 1.222 1.871.868 2.328.665.07-.517.277-.869.508-1.071-1.777-.202-3.643-.888-3.643-3.953 0-.874.312-1.586.822-2.147-.084-.203-.356-1.017.078-2.118 0 0 .672-.213 2.199.82a7.638 7.638 0 014.007.001c1.525-1.034 2.195-.82 2.195-.82.438 1.102.164 1.915.08 2.117.512.562.822 1.274.822 2.147 0 3.073-1.871 3.75-3.652 3.946.289.25.541.736.541 1.484 0 1.068-.008 1.93-.008 2.193 0 .213.146.463.551.385A8.003 8.003 0 0016 8c0-4.418-3.584-8-8.002-8\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "15.605",
      "viewBox": "0 0 16 15.605"
    }
  };
  _exports.default = _default;
});