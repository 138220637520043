define("ember-svg-jar/inlined/customers-rails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#CCCCC9\" d=\"M31.726 52.193s-4.505-9.927-4.505-20.877 4.86-15.914 6.909-17.705c2.046-1.792 6.703-4.606 11.002-4.606 4.297 0 6.805 2.814 6.805 2.814l1.33-1.79s-7.164-7.012-16.271-7.012c-9.109 0-17.295 6.756-21.134 13.561-3.838 6.807-10.285 19.497-10.285 32.954l.153 2.661h25.996z\"/><path fill=\"#CCCCC9\" d=\"M35.868 47.64l-5.142-.308-.997-3.506h4.296zM28.577 31.75l-.204 3.403 4.067 1.816-.128-3.223zM29.909 22.846l-1.254 2.56 3.299 2.839.818-3.197zM31.774 16.655l2.048-1.817 2.022 2.867-1.177 2.097zM37.813 11.795l2.764-.718.383 1.563-1.866 1.509zM46.256 10.516v1.458l2.61.357v-1.227zM49.454 6.243l.639-.871-3.247-2.405-.103 1.842zM33.259 0l.589 2.763h3.991L37.454.128zM25.046 1.51l2.406 2.636-3.275 1.891-2.302-2.839zM14.122 9.874l3.481 2.305-2.662 3.121-3.531-2.355zM5.475 22.795l4.528 1.767-1.434 3.811-4.603-1.458zM5.679 39.452l-.869 5.065L0 44.005l.587-4.913zM9.575 63.048c2.016-.251 2.762-2.381 2.762-3.973 0-1.593-.787-2.726-2.149-3.263-1.362-.537-3.933-.44-3.933-.44H2.974v11.57h2.974v-2.993l2.649 2.629h4.547l-3.569-3.53zm-.94-2.516H6.064v-2.014h2.571v2.014zM23.946 58.039c0-.787-.634-2.629-3.262-2.629h-2.609c-2.59 0-3.127 1.861-3.127 2.858v8.328h3.031v-1.938h2.937v1.938h3.031c-.001.001-.001-7.771-.001-8.557zm-3.108 3.396h-2.821v-3.107h2.821v3.107zM26.959 55.428h3.128v11.016h-3.128zM36.438 63.316v-7.888h-3.126v11.015h7.541v-3.127zM48.45 59.558h-2.418l-.211-.022V58.48h5.026v-3.07c-1.927 0-4.253-.003-4.698 0-2.42.018-3.705 1.323-3.705 2.845v1.172c0 2.833 3.014 3.197 4.012 3.197h1.504l.05.023s.537.016.537.7c0 .684-.537.663-.537.663h-5.412v2.685h5.084c2.314 0 3.704-1.246 3.704-2.77v-1.169c0-2.834-1.938-3.198-2.936-3.198z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "53.267",
      "height": "66.942",
      "viewBox": "0 0 53.267 66.942"
    }
  };
  _exports.default = _default;
});