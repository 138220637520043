define('url/-base-url', ['exports', 'url/-url-search-params'], function (exports, _urlSearchParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var BaseURL = function () {
    function BaseURL(url) {
      _classCallCheck(this, BaseURL);

      if (!url) {
        throw new TypeError('Invalid argument');
      }
    }

    _createClass(BaseURL, [{
      key: '_setPart',
      value: function _setPart(part, value) {
        this._urlObject[part] = value;
      }
    }, {
      key: 'toString',
      value: function toString() {
        return this.href;
      }
    }, {
      key: 'href',
      get: function get() {
        return this._urlObject.href;
      },
      set: function set(value) {
        this._setPart('href', value);
      }
    }, {
      key: 'protocol',
      get: function get() {
        return this._urlObject.protocol;
      },
      set: function set(value) {
        this._setPart('protocol', value);
      }
    }, {
      key: 'host',
      get: function get() {
        return this._urlObject.host;
      },
      set: function set(value) {
        this._setPart('host', value);
      }
    }, {
      key: 'origin',
      get: function get() {
        return this._urlObject.origin;
      }
    }, {
      key: 'hostname',
      get: function get() {
        return this._urlObject.hostname;
      },
      set: function set(value) {
        this._setPart('hostname', value);
      }
    }, {
      key: 'port',
      get: function get() {
        return this._urlObject.port;
      },
      set: function set(value) {
        this._setPart('port', value);
      }
    }, {
      key: 'pathname',
      get: function get() {
        return this._urlObject.pathname;
      },
      set: function set(value) {
        this._setPart('pathname', value);
      }
    }, {
      key: 'search',
      get: function get() {
        return this._urlObject.search;
      },
      set: function set(value) {
        this._setPart('search', value);
      }
    }, {
      key: 'hash',
      get: function get() {
        return this._urlObject.hash;
      },
      set: function set(value) {
        this._setPart('hash', value);
      }
    }]);

    return BaseURL;
  }();

  (0, _urlSearchParams.upgradeClass)(BaseURL);

  exports.default = BaseURL;
});