define("ember-svg-jar/inlined/icon-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"icon-help_svg__st0\" d=\"M3.001 4.254S2.847.5 6.806.5s4.885 4.371 1.851 6.067c-2.37 1.325-2.211 4.268-2.211 4.268\"/><circle class=\"icon-help_svg__st1\" cx=\"6.446\" cy=\"14.074\" r=\".926\"/>",
    "attrs": {
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});