define("ember-svg-jar/inlined/icon-currency-USD", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"10\" cy=\"10\" r=\"10\" fill=\"#aaa9a9\"/><g fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M8.083 12.876c.528.729 1.277.957 1.917.957 1.059 0 1.917-.858 1.917-1.917s-.432-1.87-1.917-2.075c-1.448-.2-1.917-.976-1.917-1.917 0-1.059.858-1.757 1.917-1.757.64 0 1.208.314 1.556.797M10 4.333v1.834M10 13.833v1.834\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});