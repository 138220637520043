define("ember-svg-jar/inlined/icon-branch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"4.94\" cy=\"2.83\" r=\"1.83\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><circle cx=\"11.78\" cy=\"5.15\" r=\"1.83\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><circle cx=\"4.98\" cy=\"14.17\" r=\"1.83\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><path d=\"M11.78 6.99s.09 2.68-1.9 3.38c-1.76.62-2.92-.04-4.93 1.97V4.66\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 17 17"
    }
  };
  _exports.default = _default;
});