define("ember-svg-jar/inlined/lang-java", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g opacity=\".8\"><path d=\"M50 41.5c0-4.2-3.9-4.5-3.9-4.5h-5.6v-3.8c0-.9-.7-1.6-1.6-1.6H19.6c-.9 0-1.6.7-1.6 1.6v20.9c0 2.7 2.2 4.9 4.9 4.9h12.7c2.7 0 4.9-2.2 4.9-4.9v-4c6.7 0 9.5-4.4 9.5-8.6zm-9.5 5.3v-6.7h5.2s1.2-.1 1.2 1.7c0 5.1-5.5 5.1-6.4 5z\" fill=\"#dc1f26\"/><path d=\"M28.7 29.8s0 0 0 0c-.5.1-1-.2-1.2-.7 0-.1-.7-3 1.8-6.7 2.7-3.9 1.6-6 .2-7-.4-.3-.5-.9-.2-1.3s.9-11.5 1.3-11.2c2.9 2.2 3 16.7.3 20.7-2 2.9-1.5 5.1-1.5 5.1.1.4-.2 1-.7 1.1zM24.5 23.7c1-4.3 1.5-6.4-2.4-9.4-.4-.3-1-.2-1.3.2-.3.4-.2 1 .2 1.3 2.9 2.2 2.7 3.3 1.7 7.5-1.1 4.6.4 6.1.7 6.4h0c.2.2.6.3.9.2.5-.1.8-.7.7-1.2-.1-.2-.2-.4-.3-.5-.3-.4-.9-1.5-.2-4.5zM38.8 15.1c-.5.2-.8.7-.6 1.2 1.1 3.5.3 4.3-2.8 7.1-3.4 3.1-3.2 5.2-3.1 5.6 0 .2.1.4.2.5.4.4 1 .4 1.4.1.3-.3.4-.7.3-1 0-.2.1-1.5 2.5-3.7 3.3-3 4.9-4.4 3.4-9.1-.3-.6-.8-.9-1.3-.7z\" fill=\"none\" stroke=\"#dc1f26\" stroke-miterlimit=\"10\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 61.2 61.9"
    }
  };
  _exports.default = _default;
});