define("ember-svg-jar/inlined/icon-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><circle cx=\"6.055\" cy=\"5.805\" r=\"5.305\"/><path d=\"M9.847 9.727l4.166 4.773\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});