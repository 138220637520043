define("ember-svg-jar/inlined/maintenance-grass-shade-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path opacity=\".1\" fill=\"#49623D\" d=\"M41.02 0h246.972l-15 14h-215l-10 9h121l-8 11H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "287.992",
      "height": "34",
      "viewBox": "0 0 287.992 34"
    }
  };
  _exports.default = _default;
});