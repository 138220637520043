define("ember-svg-jar/inlined/icon-language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"icon-language_svg__st0\" d=\"M3.781 10.458L.5 7.177l3.281-3.281m7.438 0L14.5 7.177l-3.281 3.281M9.246 2l-3.5 10.792\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});