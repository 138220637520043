define('url/-anchor-url', ['exports', 'url/-base-url'], function (exports, _baseUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var AnchorURL = function (_BaseURL) {
    _inherits(AnchorURL, _BaseURL);

    function AnchorURL(url, base) {
      _classCallCheck(this, AnchorURL);

      var _this = _possibleConstructorReturn(this, (AnchorURL.__proto__ || Object.getPrototypeOf(AnchorURL)).call(this, url));

      var doc = document.implementation.createHTMLDocument('');
      if (base) {
        var baseElement = doc.createElement('base');
        baseElement.href = base;
        doc.head.appendChild(baseElement);
      }
      var anchorElement = doc.createElement('a');
      anchorElement.href = url;
      doc.body.appendChild(anchorElement);

      if (anchorElement.href === '') {
        throw new TypeError('Invalid URL');
      }

      Object.defineProperty(_this, '_urlObject', { value: anchorElement });
      return _this;
    }

    return AnchorURL;
  }(_baseUrl.default);

  exports.default = AnchorURL;
});