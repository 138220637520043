define("ember-svg-jar/inlined/icon-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" stroke=\"#9ea3a8\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M13.337 13c0 1.1-.9 2-2 2H4.163c-1.1 0-2-.9-2-2V7.207c0-1.1.9-2 2-2h7.175c1.1 0 2 .9 2 2V13zM5.427 5.207V3.323a2.323 2.323 0 114.646 0v1.884\"/><path d=\"M9.429 9.287a1.68 1.68 0 00-3.358 0c0 .564.28 1.06.706 1.364V12.281c0 .166.136.301.301.301h1.375a.302.302 0 00.301-.301v-1.348-.309c.408-.305.675-.788.675-1.337z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});