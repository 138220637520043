define("ember-svg-jar/inlined/icon-push", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Push event</title><circle cx=\"7.505\" cy=\"7.354\" r=\"2.854\"/><path d=\"M14.5 7.354h-4.151m-5.698 0H.5\"/>",
    "attrs": {
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});