define("ember-svg-jar/inlined/icon-deletelogs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"icon-deletelogs_svg__st0\" d=\"M.5 2.521l9.444 9.444m0-9.444L.5 11.965M12.391 2.5H14.5m-4.375 3.162H14.5m-4.408 3.162H14.5m-1.74 3.162h1.74\"/>",
    "attrs": {
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});