define("ember-svg-jar/inlined/icon-success-dismiss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#6CB578\" d=\"M10.914 0L6.998 3.913 3.088 0 0 3.085 3.912 7 0 10.915 3.092 14l3.906-3.912L10.914 14 14 10.915 10.092 7 14 3.085z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});