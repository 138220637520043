define("ember-svg-jar/inlined/feature-icon-github", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.1 27.7h-.2C4.6 26.3 0 20.5 0 14 0 6.3 6.3 0 14 0s14 6.3 14 14c0 6.2-4 11.6-9.9 13.4-.2.1-.5 0-.6-.1-.2-.1-.3-.4-.3-.6v-5.1c0-.5-.1-.9-.2-1.2-.1-.2-.2-.3-.3-.4l-.1-.1c-.2-.2-.2-.5-.1-.7.1-.2.3-.4.6-.4h.4c.9-.1 1.7-.4 2.5-.8.4-.3.7-.5.9-.7.3-.4.5-.8.6-1.3.1-.5.2-1 .3-1.6v-.9c-.1-.9-.4-1.6-.9-2.2-.2-.3-.3-.6-.1-.9 0-.1.1-.2.1-.4v-.2c.1-.5 0-1-.1-1.4-.3 0-.6.1-.9.3-.3.2-.6.3-.9.5l-.5.3c-.2.1-.5.1-.6.1-.6-.2-1.2-.3-1.8-.3h-2.4c-.7 0-1.3.2-2 .3-.2 0-.4.1-.7-.1-.5-.4-1.1-.6-1.6-.9-.5-.3-.7-.4-.9-.4-.1.3-.2.6-.2.8 0 .3 0 .6.1.9 0 .1 0 .2.1.2.1.2 0 .6-.1.8l-.1.1c-.1.1-.2.2-.3.4-.3.4-.5.9-.6 1.5-.1.5 0 1 0 1.4.1.7.2 1.2.3 1.7.2.5.4.8.6 1.2.2.3.5.5.8.6.6.4 1.3.7 2 .8.3.1.9.1.9.1.2 0 .4.1.5.3.1.2.2.4.1.6 0 .1-.3 1.4-1.2 1.9-.9.5-2.4.6-3.4-.4-.1-.1-.2-.2-.4-.5-.3-.4-.7-1.1-1.2-1.2h-.1c0 .1-.1.5.1.8.1.2.2.3.4.5.3.3.6.7.7 1.1.5.9 1.3 1.2 1.6 1.3.5.2 1.6 0 1.9 0 .2 0 .4 0 .6.2.2.1.3.3.3.5L12 27c0 .2-.1.4-.3.6-.2 0-.4.1-.6.1zM14 1.4C7.1 1.4 1.4 7.1 1.4 14c0 5.5 3.7 10.5 9 12v-1.4c-.5 0-1.2 0-1.7-.1s-1.7-.6-2.4-2.2c-.1-.2-.3-.5-.5-.8-.1-.2-.3-.3-.4-.5-.5-.7-.6-1.9-.1-2.5.4-.5 1-.7 1.7-.4.2.1.3.2.5.3-.1-.1-.2-.2-.2-.3-.4-.5-.7-1-.9-1.6-.2-.6-.4-1.2-.4-1.9-.1-.5-.1-1.2 0-1.8.1-.8.4-1.5.8-2.1.1-.1.2-.2.2-.3v-.1c-.1-.4-.2-.9-.1-1.4 0-.5.1-1 .4-1.6.1-.4.5-.6.9-.6s1 .1 1.6.3c.5.2 1 .5 1.5.8.6-.2 1.3-.3 2-.3h2.6c.6.1 1.3.2 1.9.3l.3-.2c.3-.2.6-.3.9-.5.5-.3 1-.4 1.5-.5h.5c.4 0 .8.2.9.5.1.9.4 1.8.2 2.7v.1c0 .1 0 .3-.1.4.6.8 1 1.7 1.1 2.7v1.2c-.1.7-.2 1.3-.3 1.9-.2.7-.5 1.3-.9 1.8s-.9.9-1.3 1.1c-.7.4-1.5.7-2.3.9.2.5.3 1 .3 1.6v4.1c4.8-1.9 8-6.5 8-11.7 0-6.8-5.7-12.5-12.6-12.5zM8 18.7c.4.4.6.8.8 1 .1.2.2.3.2.4.5.5 1.2.4 1.7.1.1 0 .1-.1.2-.2h-.1c-.9-.2-1.7-.5-2.5-1l-.3-.3z\" fill=\"#97a1aa\"/>",
    "attrs": {
      "viewBox": "0 0 28 27.7"
    }
  };
  _exports.default = _default;
});