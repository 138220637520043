define("ember-svg-jar/inlined/moz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M90.5 17.8h3.2c.2 0 .4.1.6.1 3.7.6 6.7 2.2 9 5.2.1.1.1.3.4.2v-1.4c0-1.1.3-1.4 1.4-1.4H128.8v.1c-.2.2-.5.4-.7.7-3.3 3.5-6.6 6.9-9.9 10.4-.3.3-.9.6-.7 1 .1.3.8.1 1.2.1h10.1v5c-.4.6-1 .7-1.6.7h-22.8c-.4 0-.5-.2-.2-.5l.3-.3c3.5-3.7 7-7.3 10.4-11 .1-.1.3-.2.3-.5h-9.6c-.6 0-.5.3-.4.7.4 1.5.4 2.9.1 4.4-.8 3.6-3 6.1-6.1 7.9-1.7.9-3.5 1.4-5.3 1.7h-3.6c-.4-.1-.7-.2-1.1-.2-3.2-.7-5.9-2.1-8-4.6-1-1.2-1.6-2.5-2.1-3.9v5.7c0 .6-.2.8-.8.8h-3.5c-1.2 0-1.5-.3-1.5-1.5v-6.6c0-.3.2-.7-.2-.8-.2-.1-.4.3-.6.5-2.5 2.8-5 5.6-7.6 8.4-.7.7-.7.7-1.3 0-2.6-2.9-5.2-5.7-7.8-8.6-.1-.2-.2-.5-.5-.4-.2.1-.1.4-.1.6v7c0 1-.4 1.3-1.3 1.3h-4.5v-18h3.9c1.2 0 2.2.4 3 1.3l2.7 3 4.8 5.4c.3.3.5.4.8 0 .3-.4.6-.7.9-1 2.2-2.4 4.4-4.9 6.6-7.3.7-.8 1.5-1.3 2.6-1.3h3.6c.5 0 .7.2.7.8V27c1.4-4.1 4.1-6.7 7.9-8.1 1.2-.7 2.4-.9 3.6-1.1zm1.7 17.3c1.1-.1 2.2-.2 3.2-.8 2-1 3.3-2.6 3.3-4.9 0-2.2-1.1-3.7-2.9-4.8-2.6-1.5-5.9-1.3-8.3.7-2.5 2-2.6 5.6-.4 7.8 1.4 1.4 3.1 2 5.1 2z\" fill=\"#2fabdf\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 147.3 56.2"
    }
  };
  _exports.default = _default;
});