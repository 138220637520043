define("ember-svg-jar/inlined/rails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g bx:origin=\"0 0\" fill=\"#cb2026\"><path d=\"M70.9 24.9v3.7h6.7c1.4 0 3.7-1 3.8-3.8v-1.4c0-2.4-2-3.8-3.8-3.8h-3.4v-1.7h6.6v-3.7h-6.4c-1.6 0-3.8 1.4-3.8 3.9v1.3c0 2.5 2.2 3.8 3.8 3.8h3.2V25m-42.8-1s3.6-.3 3.6-5-4.4-5.1-4.4-5.1h-7.9v14.7H30v-3.5l3.4 3.5h6L34.8 24zm-1.5-3h-3.1v-3.4h3.2s.9.3.9 1.7-1 1.7-1 1.7zm14.9-6.9h-4c-2.9 0-3.8 2.6-3.8 3.8v10.7h4V26h3.8v2.6h3.9V17.9c-.1-3.1-2.9-3.8-3.9-3.8zm-.1 7.8h-3.8v-3.6s0-.8 1.3-.8H47c1.1 0 1.1.8 1.1.8v3.6zM53.8 14.1H58v14.6h-4.2zM63.9 24.9V14.1h-4.2v14.5h9.8v-3.7z\"/><path d=\"M1.4 28.6h16.3s-3.1-14.2 7.2-19.9c2.2-1.1 9.4-5.2 21.1 3.5.4-.3.7-.6.7-.6S35.9 1 24 2.2c-6 .5-13.3 6-17.7 13.2S1.4 28.6 1.4 28.6z\"/><path d=\"M1.4 28.6h16.3s-3.1-14.2 7.2-19.9c2.2-1.1 9.4-5.2 21.1 3.5.4-.3.7-.6.7-.6S35.9 1 24 2.2c-6 .5-13.3 6-17.7 13.2S1.4 28.6 1.4 28.6z\"/><path d=\"M1.4 28.6h16.3s-3.1-14.2 7.2-19.9c2.2-1.1 9.4-5.2 21.1 3.5.4-.3.7-.6.7-.6S35.9 1 24 2.2c-6 .5-13.4 6-17.7 13.2S1.4 28.6 1.4 28.6zM35.3 3.3l.1-1.4c-.2-.1-.7-.3-2-.7l-.1 1.4c.7.2 1.3.5 2 .7z\"/><path d=\"M33.3 7.7L33.2 9c.7 0 1.4.1 2 .2l.2-1.2c-.7-.2-1.4-.3-2.1-.3zm-7.6-6.4h.2L25.5 0c-.6 0-1.3 0-2 .1l.4 1.2h1.8zm1 7.5l.5 1.4c.6-.3 1.2-.5 1.8-.7l-.5-1.3c-.7.2-1.3.4-1.8.6zm-9.3-5.4l-1-1.4c-.5.3-1 .6-1.6.9l.9 1.4c.6-.3 1.1-.6 1.7-.9zm4.2 9.3l1 1.5c.3-.5.8-1 1.2-1.5l-.9-1.4c-.5.4-.9.9-1.3 1.4zm-3 6.6l1.7 1.3c.1-.8.2-1.6.4-2.4L19.2 17c-.2.8-.4 1.6-.6 2.3zm-9-9.8L8.1 8.2 6.6 9.7l1.6 1.4c.4-.5.9-1 1.4-1.6zm-6.2 9.2L1 17.8c-.4.9-.8 1.9-1 2.5l2.4.9c.2-.7.7-1.7 1-2.5zm14.9 5.8c0 1.1.1 2 .2 2.6l2.5.9c-.2-.8-.4-1.7-.5-2.7l-2.2-.8z\"/></g>",
    "attrs": {
      "viewBox": "0 0 81.4 28.7",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:bx": "https://boxy-svg.com"
    }
  };
  _exports.default = _default;
});