define("ember-svg-jar/inlined/lang-clojure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g opacity=\".8\"><path d=\"M35.4 36.6c-3-2.2-4.6-5.9-6-9.6-1.3 2-3.2 5.5-3.9 9.5 1.5.7 3.3 1.1 5.1 1.1 1.7.1 3.4-.3 4.8-1z\" fill=\"#97ca4a\"/><path d=\"M19.2 11.2c2.1 0 3.8.5 5.1 1.4 1.9-.9 4-1.4 6.3-1.4 8 0 14.5 6.5 14.5 14.5 0 4-1.7 7.7-4.3 10.3.4 0 .8.1 1.3.1 4.8 0 8-1.1 10.1-2.6.9-2.4 1.4-5 1.4-7.8 0-12.7-10.3-23-23-23-7.8 0-14.7 3.9-18.9 9.8 1.9-.8 4.4-1.3 7.5-1.3z\" fill=\"#5f7fbf\"/><path d=\"M42.1 38.7c-1.5 0-2.8-.2-3.9-.6-2.2 1.4-4.8 2.2-7.6 2.2-8 0-14.5-6.5-14.5-14.5 0-4.7 2.2-8.8 5.7-11.5-.8-.3-1.6-.4-2.5-.4-6 0-8.9 2.2-10 3.5-1 2.6-1.6 5.5-1.6 8.4 0 12.7 10.3 23 23 23 8.5 0 15.9-4.6 19.9-11.4-2.3.8-5 1.3-8.5 1.3z\" fill=\"#63b146\"/><path d=\"M37.9 35.1c2.8-2.2 4.6-5.6 4.6-9.4 0-6.6-5.4-11.9-11.9-11.9-1.4 0-2.8.3-4.1.7 2.2 2.5 3.5 6.2 4.6 9.5 1.7 4.7 3.2 9.2 6.8 11.1z\" fill=\"#97b2dc\"/><path d=\"M18.7 25.7c0 3.8 1.8 7.2 4.5 9.3 1.2-5.4 4.1-9.6 5.2-11.1-1.2-3.4-2.3-6.4-4.3-8.3-3.2 2.2-5.4 5.9-5.4 10.1z\" fill=\"#97ca4a\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 61.2 50.4"
    }
  };
  _exports.default = _default;
});