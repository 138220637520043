define("ember-svg-jar/inlined/red-blob", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M24.97 16.54c-5.586 6.124-60.745 66.819 13.364 139.713s223.609-25.156 230.83-30.372c7.221-5.217 42.521-35.916-14.579-26.462s-145.799 3.384-171.3-64.655C60.384-26.336 30.556 10.417 24.97 16.54z\" fill=\"#ffabab\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 286.569 183.104"
    }
  };
  _exports.default = _default;
});