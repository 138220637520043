define("ember-svg-jar/inlined/log.fold.closed.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 9L1 5l7-4z\" stroke=\"#555\" stroke-width=\".5\" fill=\"#666\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "10",
      "width": "10",
      "viewBox": "0 0 10 10"
    }
  };
  _exports.default = _default;
});