define("ember-svg-jar/inlined/github", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#939598\" d=\"M7.688 0A7.69 7.69 0 005.26 14.986c.385.07.523-.169.523-.372 0-.183-.006-.664-.008-1.308-2.141.468-2.592-1.027-2.592-1.027-.35-.893-.854-1.127-.854-1.127-.697-.479.053-.469.053-.469.771.057 1.178.791 1.178.791.686 1.176 1.799.838 2.236.643.072-.498.271-.836.49-1.029-1.707-.196-3.504-.855-3.504-3.803 0-.835.299-1.521.793-2.061-.077-.193-.341-.976.077-2.036 0 0 .645-.205 2.113.789a7.422 7.422 0 011.924-.259 7.443 7.443 0 011.926.259c1.467-.994 2.111-.789 2.111-.789.418 1.061.154 1.844.076 2.037.494.539.791 1.226.791 2.061 0 2.959-1.797 3.604-3.51 3.796.275.236.52.706.52 1.424 0 1.029-.008 1.856-.008 2.109 0 .206.141.448.527.372 3.053-1.023 5.256-3.901 5.256-7.296A7.689 7.689 0 007.688 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15.379",
      "height": "15.001",
      "viewBox": "0 0 15.379 15.001"
    }
  };
  _exports.default = _default;
});