define("ember-svg-jar/inlined/checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#2eb459\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><circle cx=\"91.4\" cy=\"34.4\" r=\"10.5\" fill=\"#fff\"/><path fill=\"none\" d=\"M86.9 34.7l3.9 3.2 5.1-7\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 147.3 56.2"
    }
  };
  _exports.default = _default;
});