define("ember-svg-jar/inlined/default-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle fill=\"#B1B1A7\" cx=\"25\" cy=\"25\" r=\"24\"/><circle fill=\"#E7EAE8\" cx=\"24.998\" cy=\"25\" r=\"12\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});