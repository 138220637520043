define("ember-svg-jar/inlined/customer-logos-heroku", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#6863a7\"><path d=\"M-447.3 394.8c0 .5-.4 1-1 1h-17.5c-.5 0-1-.4-1-1v-20c0-.5.4-1 1-1h17.5c.5 0 1 .4 1 1v20zm-1-22.1h-17.5c-1.2 0-2.2 1-2.2 2.2v20c0 1.2 1 2.2 2.2 2.2h17.5c1.2 0 2.2-1 2.2-2.2v-20c0-1.2-1-2.2-2.2-2.2z\"/><path d=\"M-462.5 393.4l2.7-2.5-2.7-2.4zM-452.6 383.5c-.5-.5-1.4-1.1-2.9-1.1-1.6 0-3.3.4-4.6.8v-6.9h-2.4v10.6l1.7-.8s2.8-1.3 5.3-1.3c1.2 0 1.5.7 1.5 1.2v7.3h2.4V386c0-.1 0-1.4-1-2.5zM-455.8 380.3h2.4c1.1-1.2 1.7-2.6 1.8-4h-2.4c-.3 1.4-.9 2.7-1.8 4zM-440.6 380.6h2.4v3.4h3v-3.4h2.4v9h-2.4V386h-3v3.6h-2.4zM-429.7 380.6h6.4v2h-3.9v1.5h2.8v1.9h-2.8v1.6h4.1v2h-6.6zM-417 384.6c1 0 1.4-.3 1.4-1 0-.8-.4-1-1.4-1h-1v2h1zm-3.4-4h3.5c2.3 0 3.7.8 3.7 2.9 0 1.4-.6 2.2-1.8 2.6l1.9 3.4h-2.6l-1.7-3.1h-.6v3.1h-2.4v-8.9zM-404.3 385.1c0-1.6-.7-2.7-2-2.7s-2 1-2 2.7c0 1.6.7 2.7 2 2.7s2-1 2-2.7zm-6.4 0c0-3.3 2.2-4.7 4.4-4.7s4.4 1.4 4.4 4.7c0 3.3-2.2 4.7-4.4 4.7-2.1 0-4.4-1.4-4.4-4.7zM-399.1 380.6h2.4v3.3l2.5-3.3h2.8l-3 3.6 3.2 5.4h-2.7l-2.1-3.6-.7.8v2.8h-2.4zM-388.9 386v-5.4h2.4v5.4c0 1.2.4 1.8 1.4 1.8s1.4-.6 1.4-1.8v-5.4h2.4v5.4c0 2.4-1.1 3.7-3.8 3.7-2.7 0-3.8-1.3-3.8-3.7z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "-468 372.7 86.6 24.3"
    }
  };
  _exports.default = _default;
});