define("ember-svg-jar/inlined/workers-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#c2c2c2\" d=\"M0 0l5 5 5-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "5",
      "width": "10",
      "viewBox": "0 0 10 5"
    }
  };
  _exports.default = _default;
});