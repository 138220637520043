define("ember-svg-jar/inlined/platform", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g opacity=\".4\"><path d=\"M90.9 22c.8-.4 2.2-.4 3 0l11.4 4.5c.8.3.8.9 0 1.3l-11.9 5.4c-.8.4-2.2.4-3 .1l-11.8-4.7c-.8-.3-.8-.9 0-1.2L90.9 22zM105.3 36.2l-2.2-.9-9.3 4.2c-.5.2-1.2.4-2 .4-.4 0-1.1 0-1.7-.3L81 36l-2.4 1c-.8.4-.8.9 0 1.2l11.8 4.7c.8.3 2.2.3 3-.1l11.9-5.4c.9-.3.9-.9 0-1.2z\" fill=\"#010101\"/><path d=\"M105.3 41.1l-2.2-.9-9.3 4.2c-.5.2-1.2.4-2 .4-.4 0-1.1 0-1.7-.3L81 40.9l-2.4 1c-.8.4-.8.9 0 1.2l11.8 4.7c.8.3 2.2.3 3-.1l11.9-5.4c.9-.3.9-.9 0-1.2zM105.3 31.3l-2.2-.9-9.3 4.2c-.5.2-1.2.4-2 .4-.4 0-1.1 0-1.7-.3L81 31.1l-2.4 1c-.8.4-.8.9 0 1.2L90.4 38c.8.3 2.2.3 3-.1l11.9-5.4c.9-.3.9-.9 0-1.2z\" fill=\"#010101\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 147.3 56.2"
    }
  };
  _exports.default = _default;
});