define("ember-svg-jar/inlined/icon-pull-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"4.3\" cy=\"3.34\" r=\"1.7\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><circle cx=\"4.3\" cy=\"13.8\" r=\"1.7\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><circle cx=\"12.7\" cy=\"13.8\" r=\"1.7\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><path d=\"M4.3 5.04v7.06M9.75 1.5S7.8 3.45 7.8 3.47h2.4s2.48-.15 2.48 2.46v6.14M9.75 5.44S7.8 3.49 7.8 3.47\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 17 17"
    }
  };
  _exports.default = _default;
});