define("ember-svg-jar/inlined/icon-mac", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"icon-mac_svg__st0\" d=\"M2.06 9.075v-.556c.003-.019.008-.037.01-.055.026-.213.04-.428.081-.638.141-.731.452-1.379.991-1.9.553-.534 1.216-.827 1.99-.847.353-.009.685.094 1.014.208.289.1.578.203.868.303a.59.59 0 00.402-.003c.273-.098.548-.19.821-.288.307-.111.62-.201.945-.242.281-.035.558-.005.835.046.578.108 1.093.342 1.518.757.133.129.251.271.352.424-.006.008-.008.012-.011.014l-.042.028a2.87 2.87 0 00-.883.855c-.42.657-.49 1.372-.333 2.118.131.62.472 1.113.961 1.508.194.156.402.29.636.381v.025c-.005.009-.011.017-.014.027-.069.183-.133.368-.208.548a7.256 7.256 0 01-.921 1.576c-.213.283-.435.558-.717.778-.348.272-.735.4-1.179.323a3.569 3.569 0 01-.757-.234A2.724 2.724 0 007.379 14c-.278 0-.548.052-.81.142-.242.083-.48.181-.723.26-.137.045-.281.067-.422.099h-.253c-.091-.024-.184-.041-.273-.072-.29-.1-.52-.291-.733-.501-.33-.325-.595-.703-.853-1.085-.412-.611-.704-1.279-.923-1.981a6.988 6.988 0 01-.306-1.542l-.023-.245zM9.93.5c.02.265.012.53-.035.792a3.171 3.171 0 01-.692 1.449c-.303.37-.66.672-1.11.849a2.07 2.07 0 01-.911.14c-.019-.001-.037-.005-.06-.008 0-.181-.015-.361.002-.539.11-1.121 1.027-2.3 2.436-2.632.09-.02.181-.034.271-.051h.099z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});