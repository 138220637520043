define("ember-prism/templates/components/code-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vtOSVfqw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[4,\"in-element\",[[28,\"-clear-element\",[[23,0,[\"blockElement\"]]],null]],[[\"guid\",\"guid\",\"nextSibling\"],[\"%cursor:0%\",\"%cursor:0%\",null]],{\"statements\":[[14,1]],\"parameters\":[]},null]],\"parameters\":[]},null],[7,\"code\",true],[11,\"class\",[22,\"languageClass\"]],[8],[1,[22,\"prismCode\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-prism/templates/components/code-block.hbs"
    }
  });

  _exports.default = _default;
});