define("ember-svg-jar/inlined/icon-environment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"icon-environment_svg__st0\" d=\"M7.5 14.5l6.911-2.743V2.486L7.411.5.6 2.486v8.987z\"/><path class=\"icon-environment_svg__st0\" d=\"M.6 2.486l7 2.082 6.811-2.082M7.6 4.568V14.2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});