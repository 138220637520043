define('url/-node-url', ['exports', 'url/-base-url'], function (exports, _baseUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.URLSearchParams = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var isFastBoot = typeof FastBoot !== 'undefined';
  var NodeURL = {};
  var NodeURLSearchParams = {};

  if (isFastBoot) {
    var _url = FastBoot.require('url');
    NodeURL = _url.URL;
    exports.URLSearchParams = NodeURLSearchParams = _url.URLSearchParams;

    if (!NodeURL) {
      NodeURL = function (_BaseURL) {
        _inherits(NodeURL, _BaseURL);

        function NodeURL(url, base) {
          _classCallCheck(this, NodeURL);

          var _this = _possibleConstructorReturn(this, (NodeURL.__proto__ || Object.getPrototypeOf(NodeURL)).call(this, url));

          if (base) {
            url = _url.resolve(base, url);
          }

          url = _url.parse(url);

          if (url.href === '') {
            throw new TypeError('Invalid URL');
          }

          Object.defineProperty(_this, '_urlObject', {
            value: url,
            configurable: true
          });
          return _this;
        }

        _createClass(NodeURL, [{
          key: '_setPart',
          value: function _setPart(part, value) {
            this._urlObject[part] = value;
            var url = _url.parse(_url.format(this._urlObject));
            Object.defineProperty(this, '_urlObject', {
              value: url,
              configurable: true
            });
          }
        }]);

        return NodeURL;
      }(_baseUrl.default);
    }
  }

  exports.URLSearchParams = NodeURLSearchParams;
  exports.default = NodeURL;
});