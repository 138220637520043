define('yamljs', ['exports'], function (exports) { 'use strict';

	var commonjsGlobal = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};

	function commonjsRequire () {
		throw new Error('Dynamic requires are not currently supported by rollup-plugin-commonjs');
	}

	// Generated by CoffeeScript 1.12.4
	var Pattern;

	Pattern = (function() {
	  Pattern.prototype.regex = null;

	  Pattern.prototype.rawRegex = null;

	  Pattern.prototype.cleanedRegex = null;

	  Pattern.prototype.mapping = null;

	  function Pattern(rawRegex, modifiers) {
	    var _char, capturingBracketNumber, cleanedRegex, i, len, mapping, name, part, subChar;
	    if (modifiers == null) {
	      modifiers = '';
	    }
	    cleanedRegex = '';
	    len = rawRegex.length;
	    mapping = null;
	    capturingBracketNumber = 0;
	    i = 0;
	    while (i < len) {
	      _char = rawRegex.charAt(i);
	      if (_char === '\\') {
	        cleanedRegex += rawRegex.slice(i, +(i + 1) + 1 || 9e9);
	        i++;
	      } else if (_char === '(') {
	        if (i < len - 2) {
	          part = rawRegex.slice(i, +(i + 2) + 1 || 9e9);
	          if (part === '(?:') {
	            i += 2;
	            cleanedRegex += part;
	          } else if (part === '(?<') {
	            capturingBracketNumber++;
	            i += 2;
	            name = '';
	            while (i + 1 < len) {
	              subChar = rawRegex.charAt(i + 1);
	              if (subChar === '>') {
	                cleanedRegex += '(';
	                i++;
	                if (name.length > 0) {
	                  if (mapping == null) {
	                    mapping = {};
	                  }
	                  mapping[name] = capturingBracketNumber;
	                }
	                break;
	              } else {
	                name += subChar;
	              }
	              i++;
	            }
	          } else {
	            cleanedRegex += _char;
	            capturingBracketNumber++;
	          }
	        } else {
	          cleanedRegex += _char;
	        }
	      } else {
	        cleanedRegex += _char;
	      }
	      i++;
	    }
	    this.rawRegex = rawRegex;
	    this.cleanedRegex = cleanedRegex;
	    this.regex = new RegExp(this.cleanedRegex, 'g' + modifiers.replace('g', ''));
	    this.mapping = mapping;
	  }

	  Pattern.prototype.exec = function(str) {
	    var index, matches, name, ref;
	    this.regex.lastIndex = 0;
	    matches = this.regex.exec(str);
	    if (matches == null) {
	      return null;
	    }
	    if (this.mapping != null) {
	      ref = this.mapping;
	      for (name in ref) {
	        index = ref[name];
	        matches[name] = matches[index];
	      }
	    }
	    return matches;
	  };

	  Pattern.prototype.test = function(str) {
	    this.regex.lastIndex = 0;
	    return this.regex.test(str);
	  };

	  Pattern.prototype.replace = function(str, replacement) {
	    this.regex.lastIndex = 0;
	    return str.replace(this.regex, replacement);
	  };

	  Pattern.prototype.replaceAll = function(str, replacement, limit) {
	    var count;
	    if (limit == null) {
	      limit = 0;
	    }
	    this.regex.lastIndex = 0;
	    count = 0;
	    while (this.regex.test(str) && (limit === 0 || count < limit)) {
	      this.regex.lastIndex = 0;
	      str = str.replace(this.regex, replacement);
	      count++;
	    }
	    return [str, count];
	  };

	  return Pattern;

	})();

	var Pattern_1 = Pattern;

	// Generated by CoffeeScript 1.12.4
	var Pattern$1, Utils,
	  hasProp = {}.hasOwnProperty;

	Pattern$1 = Pattern_1;

	Utils = (function() {
	  function Utils() {}

	  Utils.REGEX_LEFT_TRIM_BY_CHAR = {};

	  Utils.REGEX_RIGHT_TRIM_BY_CHAR = {};

	  Utils.REGEX_SPACES = /\s+/g;

	  Utils.REGEX_DIGITS = /^\d+$/;

	  Utils.REGEX_OCTAL = /[^0-7]/gi;

	  Utils.REGEX_HEXADECIMAL = /[^a-f0-9]/gi;

	  Utils.PATTERN_DATE = new Pattern$1('^' + '(?<year>[0-9][0-9][0-9][0-9])' + '-(?<month>[0-9][0-9]?)' + '-(?<day>[0-9][0-9]?)' + '(?:(?:[Tt]|[ \t]+)' + '(?<hour>[0-9][0-9]?)' + ':(?<minute>[0-9][0-9])' + ':(?<second>[0-9][0-9])' + '(?:\.(?<fraction>[0-9]*))?' + '(?:[ \t]*(?<tz>Z|(?<tz_sign>[-+])(?<tz_hour>[0-9][0-9]?)' + '(?::(?<tz_minute>[0-9][0-9]))?))?)?' + '$', 'i');

	  Utils.LOCAL_TIMEZONE_OFFSET = new Date().getTimezoneOffset() * 60 * 1000;

	  Utils.trim = function(str, _char) {
	    var regexLeft, regexRight;
	    if (_char == null) {
	      _char = '\\s';
	    }
	    regexLeft = this.REGEX_LEFT_TRIM_BY_CHAR[_char];
	    if (regexLeft == null) {
	      this.REGEX_LEFT_TRIM_BY_CHAR[_char] = regexLeft = new RegExp('^' + _char + '' + _char + '*');
	    }
	    regexLeft.lastIndex = 0;
	    regexRight = this.REGEX_RIGHT_TRIM_BY_CHAR[_char];
	    if (regexRight == null) {
	      this.REGEX_RIGHT_TRIM_BY_CHAR[_char] = regexRight = new RegExp(_char + '' + _char + '*$');
	    }
	    regexRight.lastIndex = 0;
	    return str.replace(regexLeft, '').replace(regexRight, '');
	  };

	  Utils.ltrim = function(str, _char) {
	    var regexLeft;
	    if (_char == null) {
	      _char = '\\s';
	    }
	    regexLeft = this.REGEX_LEFT_TRIM_BY_CHAR[_char];
	    if (regexLeft == null) {
	      this.REGEX_LEFT_TRIM_BY_CHAR[_char] = regexLeft = new RegExp('^' + _char + '' + _char + '*');
	    }
	    regexLeft.lastIndex = 0;
	    return str.replace(regexLeft, '');
	  };

	  Utils.rtrim = function(str, _char) {
	    var regexRight;
	    if (_char == null) {
	      _char = '\\s';
	    }
	    regexRight = this.REGEX_RIGHT_TRIM_BY_CHAR[_char];
	    if (regexRight == null) {
	      this.REGEX_RIGHT_TRIM_BY_CHAR[_char] = regexRight = new RegExp(_char + '' + _char + '*$');
	    }
	    regexRight.lastIndex = 0;
	    return str.replace(regexRight, '');
	  };

	  Utils.isEmpty = function(value) {
	    return !value || value === '' || value === '0' || (value instanceof Array && value.length === 0) || this.isEmptyObject(value);
	  };

	  Utils.isEmptyObject = function(value) {
	    var k;
	    return value instanceof Object && ((function() {
	      var results;
	      results = [];
	      for (k in value) {
	        if (!hasProp.call(value, k)) continue;
	        results.push(k);
	      }
	      return results;
	    })()).length === 0;
	  };

	  Utils.subStrCount = function(string, subString, start, length) {
	    var c, i, j, len, ref, sublen;
	    c = 0;
	    string = '' + string;
	    subString = '' + subString;
	    if (start != null) {
	      string = string.slice(start);
	    }
	    if (length != null) {
	      string = string.slice(0, length);
	    }
	    len = string.length;
	    sublen = subString.length;
	    for (i = j = 0, ref = len; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
	      if (subString === string.slice(i, sublen)) {
	        c++;
	        i += sublen - 1;
	      }
	    }
	    return c;
	  };

	  Utils.isDigits = function(input) {
	    this.REGEX_DIGITS.lastIndex = 0;
	    return this.REGEX_DIGITS.test(input);
	  };

	  Utils.octDec = function(input) {
	    this.REGEX_OCTAL.lastIndex = 0;
	    return parseInt((input + '').replace(this.REGEX_OCTAL, ''), 8);
	  };

	  Utils.hexDec = function(input) {
	    this.REGEX_HEXADECIMAL.lastIndex = 0;
	    input = this.trim(input);
	    if ((input + '').slice(0, 2) === '0x') {
	      input = (input + '').slice(2);
	    }
	    return parseInt((input + '').replace(this.REGEX_HEXADECIMAL, ''), 16);
	  };

	  Utils.utf8chr = function(c) {
	    var ch;
	    ch = String.fromCharCode;
	    if (0x80 > (c %= 0x200000)) {
	      return ch(c);
	    }
	    if (0x800 > c) {
	      return ch(0xC0 | c >> 6) + ch(0x80 | c & 0x3F);
	    }
	    if (0x10000 > c) {
	      return ch(0xE0 | c >> 12) + ch(0x80 | c >> 6 & 0x3F) + ch(0x80 | c & 0x3F);
	    }
	    return ch(0xF0 | c >> 18) + ch(0x80 | c >> 12 & 0x3F) + ch(0x80 | c >> 6 & 0x3F) + ch(0x80 | c & 0x3F);
	  };

	  Utils.parseBoolean = function(input, strict) {
	    var lowerInput;
	    if (strict == null) {
	      strict = true;
	    }
	    if (typeof input === 'string') {
	      lowerInput = input.toLowerCase();
	      if (!strict) {
	        if (lowerInput === 'no') {
	          return false;
	        }
	      }
	      if (lowerInput === '0') {
	        return false;
	      }
	      if (lowerInput === 'false') {
	        return false;
	      }
	      if (lowerInput === '') {
	        return false;
	      }
	      return true;
	    }
	    return !!input;
	  };

	  Utils.isNumeric = function(input) {
	    this.REGEX_SPACES.lastIndex = 0;
	    return typeof input === 'number' || typeof input === 'string' && !isNaN(input) && input.replace(this.REGEX_SPACES, '') !== '';
	  };

	  Utils.stringToDate = function(str) {
	    var date, day, fraction, hour, info, minute, month, second, tz_hour, tz_minute, tz_offset, year;
	    if (!(str != null ? str.length : void 0)) {
	      return null;
	    }
	    info = this.PATTERN_DATE.exec(str);
	    if (!info) {
	      return null;
	    }
	    year = parseInt(info.year, 10);
	    month = parseInt(info.month, 10) - 1;
	    day = parseInt(info.day, 10);
	    if (info.hour == null) {
	      date = new Date(Date.UTC(year, month, day));
	      return date;
	    }
	    hour = parseInt(info.hour, 10);
	    minute = parseInt(info.minute, 10);
	    second = parseInt(info.second, 10);
	    if (info.fraction != null) {
	      fraction = info.fraction.slice(0, 3);
	      while (fraction.length < 3) {
	        fraction += '0';
	      }
	      fraction = parseInt(fraction, 10);
	    } else {
	      fraction = 0;
	    }
	    if (info.tz != null) {
	      tz_hour = parseInt(info.tz_hour, 10);
	      if (info.tz_minute != null) {
	        tz_minute = parseInt(info.tz_minute, 10);
	      } else {
	        tz_minute = 0;
	      }
	      tz_offset = (tz_hour * 60 + tz_minute) * 60000;
	      if ('-' === info.tz_sign) {
	        tz_offset *= -1;
	      }
	    }
	    date = new Date(Date.UTC(year, month, day, hour, minute, second, fraction));
	    if (tz_offset) {
	      date.setTime(date.getTime() - tz_offset);
	    }
	    return date;
	  };

	  Utils.strRepeat = function(str, number) {
	    var i, res;
	    res = '';
	    i = 0;
	    while (i < number) {
	      res += str;
	      i++;
	    }
	    return res;
	  };

	  Utils.getStringFromFile = function(path, callback) {
	    var data, fs, j, len1, name, ref, req, xhr;
	    if (callback == null) {
	      callback = null;
	    }
	    xhr = null;
	    if (typeof window !== "undefined" && window !== null) {
	      if (window.XMLHttpRequest) {
	        xhr = new XMLHttpRequest();
	      } else if (window.ActiveXObject) {
	        ref = ["Msxml2.XMLHTTP.6.0", "Msxml2.XMLHTTP.3.0", "Msxml2.XMLHTTP", "Microsoft.XMLHTTP"];
	        for (j = 0, len1 = ref.length; j < len1; j++) {
	          name = ref[j];
	          try {
	            xhr = new ActiveXObject(name);
	          } catch (error) {}
	        }
	      }
	    }
	    if (xhr != null) {
	      if (callback != null) {
	        xhr.onreadystatechange = function() {
	          if (xhr.readyState === 4) {
	            if (xhr.status === 200 || xhr.status === 0) {
	              return callback(xhr.responseText);
	            } else {
	              return callback(null);
	            }
	          }
	        };
	        xhr.open('GET', path, true);
	        return xhr.send(null);
	      } else {
	        xhr.open('GET', path, false);
	        xhr.send(null);
	        if (xhr.status === 200 || xhr.status === 0) {
	          return xhr.responseText;
	        }
	        return null;
	      }
	    } else {
	      req = commonjsRequire;
	      fs = req('fs');
	      if (callback != null) {
	        return fs.readFile(path, function(err, data) {
	          if (err) {
	            return callback(null);
	          } else {
	            return callback(String(data));
	          }
	        });
	      } else {
	        data = fs.readFileSync(path);
	        if (data != null) {
	          return String(data);
	        }
	        return null;
	      }
	    }
	  };

	  return Utils;

	})();

	var Utils_1 = Utils;

	// Generated by CoffeeScript 1.12.4
	var Pattern$2, Unescaper, Utils$1;

	Utils$1 = Utils_1;

	Pattern$2 = Pattern_1;

	Unescaper = (function() {
	  function Unescaper() {}

	  Unescaper.PATTERN_ESCAPED_CHARACTER = new Pattern$2('\\\\([0abt\tnvfre "\\/\\\\N_LP]|x[0-9a-fA-F]{2}|u[0-9a-fA-F]{4}|U[0-9a-fA-F]{8})');

	  Unescaper.unescapeSingleQuotedString = function(value) {
	    return value.replace(/\'\'/g, '\'');
	  };

	  Unescaper.unescapeDoubleQuotedString = function(value) {
	    if (this._unescapeCallback == null) {
	      this._unescapeCallback = (function(_this) {
	        return function(str) {
	          return _this.unescapeCharacter(str);
	        };
	      })(this);
	    }
	    return this.PATTERN_ESCAPED_CHARACTER.replace(value, this._unescapeCallback);
	  };

	  Unescaper.unescapeCharacter = function(value) {
	    var ch;
	    ch = String.fromCharCode;
	    switch (value.charAt(1)) {
	      case '0':
	        return ch(0);
	      case 'a':
	        return ch(7);
	      case 'b':
	        return ch(8);
	      case 't':
	        return "\t";
	      case "\t":
	        return "\t";
	      case 'n':
	        return "\n";
	      case 'v':
	        return ch(11);
	      case 'f':
	        return ch(12);
	      case 'r':
	        return ch(13);
	      case 'e':
	        return ch(27);
	      case ' ':
	        return ' ';
	      case '"':
	        return '"';
	      case '/':
	        return '/';
	      case '\\':
	        return '\\';
	      case 'N':
	        return ch(0x0085);
	      case '_':
	        return ch(0x00A0);
	      case 'L':
	        return ch(0x2028);
	      case 'P':
	        return ch(0x2029);
	      case 'x':
	        return Utils$1.utf8chr(Utils$1.hexDec(value.substr(2, 2)));
	      case 'u':
	        return Utils$1.utf8chr(Utils$1.hexDec(value.substr(2, 4)));
	      case 'U':
	        return Utils$1.utf8chr(Utils$1.hexDec(value.substr(2, 8)));
	      default:
	        return '';
	    }
	  };

	  return Unescaper;

	})();

	var Unescaper_1 = Unescaper;

	// Generated by CoffeeScript 1.12.4
	var Escaper, Pattern$3;

	Pattern$3 = Pattern_1;

	Escaper = (function() {
	  var ch;

	  function Escaper() {}

	  Escaper.LIST_ESCAPEES = ['\\', '\\\\', '\\"', '"', "\x00", "\x01", "\x02", "\x03", "\x04", "\x05", "\x06", "\x07", "\x08", "\x09", "\x0a", "\x0b", "\x0c", "\x0d", "\x0e", "\x0f", "\x10", "\x11", "\x12", "\x13", "\x14", "\x15", "\x16", "\x17", "\x18", "\x19", "\x1a", "\x1b", "\x1c", "\x1d", "\x1e", "\x1f", (ch = String.fromCharCode)(0x0085), ch(0x00A0), ch(0x2028), ch(0x2029)];

	  Escaper.LIST_ESCAPED = ['\\\\', '\\"', '\\"', '\\"', "\\0", "\\x01", "\\x02", "\\x03", "\\x04", "\\x05", "\\x06", "\\a", "\\b", "\\t", "\\n", "\\v", "\\f", "\\r", "\\x0e", "\\x0f", "\\x10", "\\x11", "\\x12", "\\x13", "\\x14", "\\x15", "\\x16", "\\x17", "\\x18", "\\x19", "\\x1a", "\\e", "\\x1c", "\\x1d", "\\x1e", "\\x1f", "\\N", "\\_", "\\L", "\\P"];

	  Escaper.MAPPING_ESCAPEES_TO_ESCAPED = (function() {
	    var i, j, mapping, ref;
	    mapping = {};
	    for (i = j = 0, ref = Escaper.LIST_ESCAPEES.length; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
	      mapping[Escaper.LIST_ESCAPEES[i]] = Escaper.LIST_ESCAPED[i];
	    }
	    return mapping;
	  })();

	  Escaper.PATTERN_CHARACTERS_TO_ESCAPE = new Pattern$3('[\\x00-\\x1f]|\xc2\x85|\xc2\xa0|\xe2\x80\xa8|\xe2\x80\xa9');

	  Escaper.PATTERN_MAPPING_ESCAPEES = new Pattern$3(Escaper.LIST_ESCAPEES.join('|').split('\\').join('\\\\'));

	  Escaper.PATTERN_SINGLE_QUOTING = new Pattern$3('[\\s\'":{}[\\],&*#?]|^[-?|<>=!%@`]');

	  Escaper.requiresDoubleQuoting = function(value) {
	    return this.PATTERN_CHARACTERS_TO_ESCAPE.test(value);
	  };

	  Escaper.escapeWithDoubleQuotes = function(value) {
	    var result;
	    result = this.PATTERN_MAPPING_ESCAPEES.replace(value, (function(_this) {
	      return function(str) {
	        return _this.MAPPING_ESCAPEES_TO_ESCAPED[str];
	      };
	    })(this));
	    return '"' + result + '"';
	  };

	  Escaper.requiresSingleQuoting = function(value) {
	    return this.PATTERN_SINGLE_QUOTING.test(value);
	  };

	  Escaper.escapeWithSingleQuotes = function(value) {
	    return "'" + value.replace(/'/g, "''") + "'";
	  };

	  return Escaper;

	})();

	var Escaper_1 = Escaper;

	// Generated by CoffeeScript 1.12.4
	var ParseException,
	  extend = function(child, parent) { for (var key in parent) { if (hasProp$1.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
	  hasProp$1 = {}.hasOwnProperty;

	ParseException = (function(superClass) {
	  extend(ParseException, superClass);

	  function ParseException(message, parsedLine, snippet) {
	    this.message = message;
	    this.parsedLine = parsedLine;
	    this.snippet = snippet;
	  }

	  ParseException.prototype.toString = function() {
	    if ((this.parsedLine != null) && (this.snippet != null)) {
	      return '<ParseException> ' + this.message + ' (line ' + this.parsedLine + ': \'' + this.snippet + '\')';
	    } else {
	      return '<ParseException> ' + this.message;
	    }
	  };

	  return ParseException;

	})(Error);

	var ParseException_1 = ParseException;

	// Generated by CoffeeScript 1.12.4
	var ParseMore,
	  extend$1 = function(child, parent) { for (var key in parent) { if (hasProp$2.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
	  hasProp$2 = {}.hasOwnProperty;

	ParseMore = (function(superClass) {
	  extend$1(ParseMore, superClass);

	  function ParseMore(message, parsedLine, snippet) {
	    this.message = message;
	    this.parsedLine = parsedLine;
	    this.snippet = snippet;
	  }

	  ParseMore.prototype.toString = function() {
	    if ((this.parsedLine != null) && (this.snippet != null)) {
	      return '<ParseMore> ' + this.message + ' (line ' + this.parsedLine + ': \'' + this.snippet + '\')';
	    } else {
	      return '<ParseMore> ' + this.message;
	    }
	  };

	  return ParseMore;

	})(Error);

	var ParseMore_1 = ParseMore;

	// Generated by CoffeeScript 1.12.4
	var DumpException,
	  extend$2 = function(child, parent) { for (var key in parent) { if (hasProp$3.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
	  hasProp$3 = {}.hasOwnProperty;

	DumpException = (function(superClass) {
	  extend$2(DumpException, superClass);

	  function DumpException(message, parsedLine, snippet) {
	    this.message = message;
	    this.parsedLine = parsedLine;
	    this.snippet = snippet;
	  }

	  DumpException.prototype.toString = function() {
	    if ((this.parsedLine != null) && (this.snippet != null)) {
	      return '<DumpException> ' + this.message + ' (line ' + this.parsedLine + ': \'' + this.snippet + '\')';
	    } else {
	      return '<DumpException> ' + this.message;
	    }
	  };

	  return DumpException;

	})(Error);

	// Generated by CoffeeScript 1.12.4
	var Escaper$1, Inline, ParseException$1, ParseMore$1, Pattern$4, Unescaper$1, Utils$2,
	  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

	Pattern$4 = Pattern_1;

	Unescaper$1 = Unescaper_1;

	Escaper$1 = Escaper_1;

	Utils$2 = Utils_1;

	ParseException$1 = ParseException_1;

	ParseMore$1 = ParseMore_1;

	Inline = (function() {
	  function Inline() {}

	  Inline.REGEX_QUOTED_STRING = '(?:"(?:[^"\\\\]*(?:\\\\.[^"\\\\]*)*)"|\'(?:[^\']*(?:\'\'[^\']*)*)\')';

	  Inline.PATTERN_TRAILING_COMMENTS = new Pattern$4('^\\s*#.*$');

	  Inline.PATTERN_QUOTED_SCALAR = new Pattern$4('^' + Inline.REGEX_QUOTED_STRING);

	  Inline.PATTERN_THOUSAND_NUMERIC_SCALAR = new Pattern$4('^(-|\\+)?[0-9,]+(\\.[0-9]+)?$');

	  Inline.PATTERN_SCALAR_BY_DELIMITERS = {};

	  Inline.settings = {};

	  Inline.configure = function(exceptionOnInvalidType, objectDecoder) {
	    if (exceptionOnInvalidType == null) {
	      exceptionOnInvalidType = null;
	    }
	    if (objectDecoder == null) {
	      objectDecoder = null;
	    }
	    this.settings.exceptionOnInvalidType = exceptionOnInvalidType;
	    this.settings.objectDecoder = objectDecoder;
	  };

	  Inline.parse = function(value, exceptionOnInvalidType, objectDecoder) {
	    var context, result;
	    if (exceptionOnInvalidType == null) {
	      exceptionOnInvalidType = false;
	    }
	    if (objectDecoder == null) {
	      objectDecoder = null;
	    }
	    this.settings.exceptionOnInvalidType = exceptionOnInvalidType;
	    this.settings.objectDecoder = objectDecoder;
	    if (value == null) {
	      return '';
	    }
	    value = Utils$2.trim(value);
	    if (0 === value.length) {
	      return '';
	    }
	    context = {
	      exceptionOnInvalidType: exceptionOnInvalidType,
	      objectDecoder: objectDecoder,
	      i: 0
	    };
	    switch (value.charAt(0)) {
	      case '[':
	        result = this.parseSequence(value, context);
	        ++context.i;
	        break;
	      case '{':
	        result = this.parseMapping(value, context);
	        ++context.i;
	        break;
	      default:
	        result = this.parseScalar(value, null, ['"', "'"], context);
	    }
	    if (this.PATTERN_TRAILING_COMMENTS.replace(value.slice(context.i), '') !== '') {
	      throw new ParseException$1('Unexpected characters near "' + value.slice(context.i) + '".');
	    }
	    return result;
	  };

	  Inline.dump = function(value, exceptionOnInvalidType, objectEncoder) {
	    var ref, result, type;
	    if (exceptionOnInvalidType == null) {
	      exceptionOnInvalidType = false;
	    }
	    if (objectEncoder == null) {
	      objectEncoder = null;
	    }
	    if (value == null) {
	      return 'null';
	    }
	    type = typeof value;
	    if (type === 'object') {
	      if (value instanceof Date) {
	        return value.toISOString();
	      } else if (objectEncoder != null) {
	        result = objectEncoder(value);
	        if (typeof result === 'string' || (result != null)) {
	          return result;
	        }
	      }
	      return this.dumpObject(value);
	    }
	    if (type === 'boolean') {
	      return (value ? 'true' : 'false');
	    }
	    if (Utils$2.isDigits(value)) {
	      return (type === 'string' ? "'" + value + "'" : String(parseInt(value)));
	    }
	    if (Utils$2.isNumeric(value)) {
	      return (type === 'string' ? "'" + value + "'" : String(parseFloat(value)));
	    }
	    if (type === 'number') {
	      return (value === 2e308 ? '.Inf' : (value === -2e308 ? '-.Inf' : (isNaN(value) ? '.NaN' : value)));
	    }
	    if (Escaper$1.requiresDoubleQuoting(value)) {
	      return Escaper$1.escapeWithDoubleQuotes(value);
	    }
	    if (Escaper$1.requiresSingleQuoting(value)) {
	      return Escaper$1.escapeWithSingleQuotes(value);
	    }
	    if ('' === value) {
	      return '""';
	    }
	    if (Utils$2.PATTERN_DATE.test(value)) {
	      return "'" + value + "'";
	    }
	    if ((ref = value.toLowerCase()) === 'null' || ref === '~' || ref === 'true' || ref === 'false') {
	      return "'" + value + "'";
	    }
	    return value;
	  };

	  Inline.dumpObject = function(value, exceptionOnInvalidType, objectSupport) {
	    var j, key, len1, output, val;
	    if (objectSupport == null) {
	      objectSupport = null;
	    }
	    if (value instanceof Array) {
	      output = [];
	      for (j = 0, len1 = value.length; j < len1; j++) {
	        val = value[j];
	        output.push(this.dump(val));
	      }
	      return '[' + output.join(', ') + ']';
	    } else {
	      output = [];
	      for (key in value) {
	        val = value[key];
	        output.push(this.dump(key) + ': ' + this.dump(val));
	      }
	      return '{' + output.join(', ') + '}';
	    }
	  };

	  Inline.parseScalar = function(scalar, delimiters, stringDelimiters, context, evaluate) {
	    var i, joinedDelimiters, match, output, pattern, ref, ref1, strpos, tmp;
	    if (delimiters == null) {
	      delimiters = null;
	    }
	    if (stringDelimiters == null) {
	      stringDelimiters = ['"', "'"];
	    }
	    if (context == null) {
	      context = null;
	    }
	    if (evaluate == null) {
	      evaluate = true;
	    }
	    if (context == null) {
	      context = {
	        exceptionOnInvalidType: this.settings.exceptionOnInvalidType,
	        objectDecoder: this.settings.objectDecoder,
	        i: 0
	      };
	    }
	    i = context.i;
	    if (ref = scalar.charAt(i), indexOf.call(stringDelimiters, ref) >= 0) {
	      output = this.parseQuotedScalar(scalar, context);
	      i = context.i;
	      if (delimiters != null) {
	        tmp = Utils$2.ltrim(scalar.slice(i), ' ');
	        if (!(ref1 = tmp.charAt(0), indexOf.call(delimiters, ref1) >= 0)) {
	          throw new ParseException$1('Unexpected characters (' + scalar.slice(i) + ').');
	        }
	      }
	    } else {
	      if (!delimiters) {
	        output = scalar.slice(i);
	        i += output.length;
	        strpos = output.indexOf(' #');
	        if (strpos !== -1) {
	          output = Utils$2.rtrim(output.slice(0, strpos));
	        }
	      } else {
	        joinedDelimiters = delimiters.join('|');
	        pattern = this.PATTERN_SCALAR_BY_DELIMITERS[joinedDelimiters];
	        if (pattern == null) {
	          pattern = new Pattern$4('^(.+?)(' + joinedDelimiters + ')');
	          this.PATTERN_SCALAR_BY_DELIMITERS[joinedDelimiters] = pattern;
	        }
	        if (match = pattern.exec(scalar.slice(i))) {
	          output = match[1];
	          i += output.length;
	        } else {
	          throw new ParseException$1('Malformed inline YAML string (' + scalar + ').');
	        }
	      }
	      if (evaluate) {
	        output = this.evaluateScalar(output, context);
	      }
	    }
	    context.i = i;
	    return output;
	  };

	  Inline.parseQuotedScalar = function(scalar, context) {
	    var i, match, output;
	    i = context.i;
	    if (!(match = this.PATTERN_QUOTED_SCALAR.exec(scalar.slice(i)))) {
	      throw new ParseMore$1('Malformed inline YAML string (' + scalar.slice(i) + ').');
	    }
	    output = match[0].substr(1, match[0].length - 2);
	    if ('"' === scalar.charAt(i)) {
	      output = Unescaper$1.unescapeDoubleQuotedString(output);
	    } else {
	      output = Unescaper$1.unescapeSingleQuotedString(output);
	    }
	    i += match[0].length;
	    context.i = i;
	    return output;
	  };

	  Inline.parseSequence = function(sequence, context) {
	    var i, isQuoted, len, output, ref, value;
	    output = [];
	    len = sequence.length;
	    i = context.i;
	    i += 1;
	    while (i < len) {
	      context.i = i;
	      switch (sequence.charAt(i)) {
	        case '[':
	          output.push(this.parseSequence(sequence, context));
	          i = context.i;
	          break;
	        case '{':
	          output.push(this.parseMapping(sequence, context));
	          i = context.i;
	          break;
	        case ']':
	          return output;
	        case ',':
	        case ' ':
	        case "\n":
	          break;
	        default:
	          isQuoted = ((ref = sequence.charAt(i)) === '"' || ref === "'");
	          value = this.parseScalar(sequence, [',', ']'], ['"', "'"], context);
	          i = context.i;
	          if (!isQuoted && typeof value === 'string' && (value.indexOf(': ') !== -1 || value.indexOf(":\n") !== -1)) {
	            try {
	              value = this.parseMapping('{' + value + '}');
	            } catch (error) {
	            }
	          }
	          output.push(value);
	          --i;
	      }
	      ++i;
	    }
	    throw new ParseMore$1('Malformed inline YAML string ' + sequence);
	  };

	  Inline.parseMapping = function(mapping, context) {
	    var done, i, key, len, output, shouldContinueWhileLoop, value;
	    output = {};
	    len = mapping.length;
	    i = context.i;
	    i += 1;
	    shouldContinueWhileLoop = false;
	    while (i < len) {
	      context.i = i;
	      switch (mapping.charAt(i)) {
	        case ' ':
	        case ',':
	        case "\n":
	          ++i;
	          context.i = i;
	          shouldContinueWhileLoop = true;
	          break;
	        case '}':
	          return output;
	      }
	      if (shouldContinueWhileLoop) {
	        shouldContinueWhileLoop = false;
	        continue;
	      }
	      key = this.parseScalar(mapping, [':', ' ', "\n"], ['"', "'"], context, false);
	      i = context.i;
	      done = false;
	      while (i < len) {
	        context.i = i;
	        switch (mapping.charAt(i)) {
	          case '[':
	            value = this.parseSequence(mapping, context);
	            i = context.i;
	            if (output[key] === void 0) {
	              output[key] = value;
	            }
	            done = true;
	            break;
	          case '{':
	            value = this.parseMapping(mapping, context);
	            i = context.i;
	            if (output[key] === void 0) {
	              output[key] = value;
	            }
	            done = true;
	            break;
	          case ':':
	          case ' ':
	          case "\n":
	            break;
	          default:
	            value = this.parseScalar(mapping, [',', '}'], ['"', "'"], context);
	            i = context.i;
	            if (output[key] === void 0) {
	              output[key] = value;
	            }
	            done = true;
	            --i;
	        }
	        ++i;
	        if (done) {
	          break;
	        }
	      }
	    }
	    throw new ParseMore$1('Malformed inline YAML string ' + mapping);
	  };

	  Inline.evaluateScalar = function(scalar, context) {
	    var cast, date, exceptionOnInvalidType, firstChar, firstSpace, firstWord, objectDecoder, raw, scalarLower, subValue, trimmedScalar;
	    scalar = Utils$2.trim(scalar);
	    scalarLower = scalar.toLowerCase();
	    switch (scalarLower) {
	      case 'null':
	      case '':
	      case '~':
	        return null;
	      case 'true':
	        return true;
	      case 'false':
	        return false;
	      case '.inf':
	        return 2e308;
	      case '.nan':
	        return 0/0;
	      case '-.inf':
	        return 2e308;
	      default:
	        firstChar = scalarLower.charAt(0);
	        switch (firstChar) {
	          case '!':
	            firstSpace = scalar.indexOf(' ');
	            if (firstSpace === -1) {
	              firstWord = scalarLower;
	            } else {
	              firstWord = scalarLower.slice(0, firstSpace);
	            }
	            switch (firstWord) {
	              case '!':
	                if (firstSpace !== -1) {
	                  return parseInt(this.parseScalar(scalar.slice(2)));
	                }
	                return null;
	              case '!str':
	                return Utils$2.ltrim(scalar.slice(4));
	              case '!!str':
	                return Utils$2.ltrim(scalar.slice(5));
	              case '!!int':
	                return parseInt(this.parseScalar(scalar.slice(5)));
	              case '!!bool':
	                return Utils$2.parseBoolean(this.parseScalar(scalar.slice(6)), false);
	              case '!!float':
	                return parseFloat(this.parseScalar(scalar.slice(7)));
	              case '!!timestamp':
	                return Utils$2.stringToDate(Utils$2.ltrim(scalar.slice(11)));
	              default:
	                if (context == null) {
	                  context = {
	                    exceptionOnInvalidType: this.settings.exceptionOnInvalidType,
	                    objectDecoder: this.settings.objectDecoder,
	                    i: 0
	                  };
	                }
	                objectDecoder = context.objectDecoder, exceptionOnInvalidType = context.exceptionOnInvalidType;
	                if (objectDecoder) {
	                  trimmedScalar = Utils$2.rtrim(scalar);
	                  firstSpace = trimmedScalar.indexOf(' ');
	                  if (firstSpace === -1) {
	                    return objectDecoder(trimmedScalar, null);
	                  } else {
	                    subValue = Utils$2.ltrim(trimmedScalar.slice(firstSpace + 1));
	                    if (!(subValue.length > 0)) {
	                      subValue = null;
	                    }
	                    return objectDecoder(trimmedScalar.slice(0, firstSpace), subValue);
	                  }
	                }
	                if (exceptionOnInvalidType) {
	                  throw new ParseException$1('Custom object support when parsing a YAML file has been disabled.');
	                }
	                return null;
	            }
	            break;
	          case '0':
	            if ('0x' === scalar.slice(0, 2)) {
	              return Utils$2.hexDec(scalar);
	            } else if (Utils$2.isDigits(scalar)) {
	              return Utils$2.octDec(scalar);
	            } else if (Utils$2.isNumeric(scalar)) {
	              return parseFloat(scalar);
	            } else {
	              return scalar;
	            }
	            break;
	          case '+':
	            if (Utils$2.isDigits(scalar)) {
	              raw = scalar;
	              cast = parseInt(raw);
	              if (raw === String(cast)) {
	                return cast;
	              } else {
	                return raw;
	              }
	            } else if (Utils$2.isNumeric(scalar)) {
	              return parseFloat(scalar);
	            } else if (this.PATTERN_THOUSAND_NUMERIC_SCALAR.test(scalar)) {
	              return parseFloat(scalar.replace(',', ''));
	            }
	            return scalar;
	          case '-':
	            if (Utils$2.isDigits(scalar.slice(1))) {
	              if ('0' === scalar.charAt(1)) {
	                return -Utils$2.octDec(scalar.slice(1));
	              } else {
	                raw = scalar.slice(1);
	                cast = parseInt(raw);
	                if (raw === String(cast)) {
	                  return -cast;
	                } else {
	                  return -raw;
	                }
	              }
	            } else if (Utils$2.isNumeric(scalar)) {
	              return parseFloat(scalar);
	            } else if (this.PATTERN_THOUSAND_NUMERIC_SCALAR.test(scalar)) {
	              return parseFloat(scalar.replace(',', ''));
	            }
	            return scalar;
	          default:
	            if (date = Utils$2.stringToDate(scalar)) {
	              return date;
	            } else if (Utils$2.isNumeric(scalar)) {
	              return parseFloat(scalar);
	            } else if (this.PATTERN_THOUSAND_NUMERIC_SCALAR.test(scalar)) {
	              return parseFloat(scalar.replace(',', ''));
	            }
	            return scalar;
	        }
	    }
	  };

	  return Inline;

	})();

	var Inline_1 = Inline;

	// Generated by CoffeeScript 1.12.4
	var Inline$1, ParseException$2, ParseMore$2, Parser, Pattern$5, Utils$3;

	Inline$1 = Inline_1;

	Pattern$5 = Pattern_1;

	Utils$3 = Utils_1;

	ParseException$2 = ParseException_1;

	ParseMore$2 = ParseMore_1;

	Parser = (function() {
	  Parser.prototype.PATTERN_FOLDED_SCALAR_ALL = new Pattern$5('^(?:(?<type>![^\\|>]*)\\s+)?(?<separator>\\||>)(?<modifiers>\\+|\\-|\\d+|\\+\\d+|\\-\\d+|\\d+\\+|\\d+\\-)?(?<comments> +#.*)?$');

	  Parser.prototype.PATTERN_FOLDED_SCALAR_END = new Pattern$5('(?<separator>\\||>)(?<modifiers>\\+|\\-|\\d+|\\+\\d+|\\-\\d+|\\d+\\+|\\d+\\-)?(?<comments> +#.*)?$');

	  Parser.prototype.PATTERN_SEQUENCE_ITEM = new Pattern$5('^\\-((?<leadspaces>\\s+)(?<value>.+?))?\\s*$');

	  Parser.prototype.PATTERN_ANCHOR_VALUE = new Pattern$5('^&(?<ref>[^ ]+) *(?<value>.*)');

	  Parser.prototype.PATTERN_COMPACT_NOTATION = new Pattern$5('^(?<key>' + Inline$1.REGEX_QUOTED_STRING + '|[^ \'"\\{\\[].*?) *\\:(\\s+(?<value>.+?))?\\s*$');

	  Parser.prototype.PATTERN_MAPPING_ITEM = new Pattern$5('^(?<key>' + Inline$1.REGEX_QUOTED_STRING + '|[^ \'"\\[\\{].*?) *\\:(\\s+(?<value>.+?))?\\s*$');

	  Parser.prototype.PATTERN_DECIMAL = new Pattern$5('\\d+');

	  Parser.prototype.PATTERN_INDENT_SPACES = new Pattern$5('^ +');

	  Parser.prototype.PATTERN_TRAILING_LINES = new Pattern$5('(\n*)$');

	  Parser.prototype.PATTERN_YAML_HEADER = new Pattern$5('^\\%YAML[: ][\\d\\.]+.*\n', 'm');

	  Parser.prototype.PATTERN_LEADING_COMMENTS = new Pattern$5('^(\\#.*?\n)+', 'm');

	  Parser.prototype.PATTERN_DOCUMENT_MARKER_START = new Pattern$5('^\\-\\-\\-.*?\n', 'm');

	  Parser.prototype.PATTERN_DOCUMENT_MARKER_END = new Pattern$5('^\\.\\.\\.\\s*$', 'm');

	  Parser.prototype.PATTERN_FOLDED_SCALAR_BY_INDENTATION = {};

	  Parser.prototype.CONTEXT_NONE = 0;

	  Parser.prototype.CONTEXT_SEQUENCE = 1;

	  Parser.prototype.CONTEXT_MAPPING = 2;

	  function Parser(offset) {
	    this.offset = offset != null ? offset : 0;
	    this.lines = [];
	    this.currentLineNb = -1;
	    this.currentLine = '';
	    this.refs = {};
	  }

	  Parser.prototype.parse = function(value, exceptionOnInvalidType, objectDecoder) {
	    var alias, allowOverwrite, block, c, context, data, e, first, i, indent, isRef, j, k, key, l, lastKey, len, len1, len2, len3, lineCount, m, matches, mergeNode, n, name, parsed, parsedItem, parser, ref, ref1, ref2, refName, refValue, val, values;
	    if (exceptionOnInvalidType == null) {
	      exceptionOnInvalidType = false;
	    }
	    if (objectDecoder == null) {
	      objectDecoder = null;
	    }
	    this.currentLineNb = -1;
	    this.currentLine = '';
	    this.lines = this.cleanup(value).split("\n");
	    data = null;
	    context = this.CONTEXT_NONE;
	    allowOverwrite = false;
	    while (this.moveToNextLine()) {
	      if (this.isCurrentLineEmpty()) {
	        continue;
	      }
	      if ("\t" === this.currentLine[0]) {
	        throw new ParseException$2('A YAML file cannot contain tabs as indentation.', this.getRealCurrentLineNb() + 1, this.currentLine);
	      }
	      isRef = mergeNode = false;
	      if (values = this.PATTERN_SEQUENCE_ITEM.exec(this.currentLine)) {
	        if (this.CONTEXT_MAPPING === context) {
	          throw new ParseException$2('You cannot define a sequence item when in a mapping');
	        }
	        context = this.CONTEXT_SEQUENCE;
	        if (data == null) {
	          data = [];
	        }
	        if ((values.value != null) && (matches = this.PATTERN_ANCHOR_VALUE.exec(values.value))) {
	          isRef = matches.ref;
	          values.value = matches.value;
	        }
	        if (!(values.value != null) || '' === Utils$3.trim(values.value, ' ') || Utils$3.ltrim(values.value, ' ').indexOf('#') === 0) {
	          if (this.currentLineNb < this.lines.length - 1 && !this.isNextLineUnIndentedCollection()) {
	            c = this.getRealCurrentLineNb() + 1;
	            parser = new Parser(c);
	            parser.refs = this.refs;
	            data.push(parser.parse(this.getNextEmbedBlock(null, true), exceptionOnInvalidType, objectDecoder));
	          } else {
	            data.push(null);
	          }
	        } else {
	          if (((ref = values.leadspaces) != null ? ref.length : void 0) && (matches = this.PATTERN_COMPACT_NOTATION.exec(values.value))) {
	            c = this.getRealCurrentLineNb();
	            parser = new Parser(c);
	            parser.refs = this.refs;
	            block = values.value;
	            indent = this.getCurrentLineIndentation();
	            if (this.isNextLineIndented(false)) {
	              block += "\n" + this.getNextEmbedBlock(indent + values.leadspaces.length + 1, true);
	            }
	            data.push(parser.parse(block, exceptionOnInvalidType, objectDecoder));
	          } else {
	            data.push(this.parseValue(values.value, exceptionOnInvalidType, objectDecoder));
	          }
	        }
	      } else if ((values = this.PATTERN_MAPPING_ITEM.exec(this.currentLine)) && values.key.indexOf(' #') === -1) {
	        if (this.CONTEXT_SEQUENCE === context) {
	          throw new ParseException$2('You cannot define a mapping item when in a sequence');
	        }
	        context = this.CONTEXT_MAPPING;
	        if (data == null) {
	          data = {};
	        }
	        Inline$1.configure(exceptionOnInvalidType, objectDecoder);
	        try {
	          key = Inline$1.parseScalar(values.key);
	        } catch (error) {
	          e = error;
	          e.parsedLine = this.getRealCurrentLineNb() + 1;
	          e.snippet = this.currentLine;
	          throw e;
	        }
	        if ('<<' === key) {
	          mergeNode = true;
	          allowOverwrite = true;
	          if (((ref1 = values.value) != null ? ref1.indexOf('*') : void 0) === 0) {
	            refName = values.value.slice(1);
	            if (this.refs[refName] == null) {
	              throw new ParseException$2('Reference "' + refName + '" does not exist.', this.getRealCurrentLineNb() + 1, this.currentLine);
	            }
	            refValue = this.refs[refName];
	            if (typeof refValue !== 'object') {
	              throw new ParseException$2('YAML merge keys used with a scalar value instead of an object.', this.getRealCurrentLineNb() + 1, this.currentLine);
	            }
	            if (refValue instanceof Array) {
	              for (i = j = 0, len = refValue.length; j < len; i = ++j) {
	                value = refValue[i];
	                if (data[name = String(i)] == null) {
	                  data[name] = value;
	                }
	              }
	            } else {
	              for (key in refValue) {
	                value = refValue[key];
	                if (data[key] == null) {
	                  data[key] = value;
	                }
	              }
	            }
	          } else {
	            if ((values.value != null) && values.value !== '') {
	              value = values.value;
	            } else {
	              value = this.getNextEmbedBlock();
	            }
	            c = this.getRealCurrentLineNb() + 1;
	            parser = new Parser(c);
	            parser.refs = this.refs;
	            parsed = parser.parse(value, exceptionOnInvalidType);
	            if (typeof parsed !== 'object') {
	              throw new ParseException$2('YAML merge keys used with a scalar value instead of an object.', this.getRealCurrentLineNb() + 1, this.currentLine);
	            }
	            if (parsed instanceof Array) {
	              for (l = 0, len1 = parsed.length; l < len1; l++) {
	                parsedItem = parsed[l];
	                if (typeof parsedItem !== 'object') {
	                  throw new ParseException$2('Merge items must be objects.', this.getRealCurrentLineNb() + 1, parsedItem);
	                }
	                if (parsedItem instanceof Array) {
	                  for (i = m = 0, len2 = parsedItem.length; m < len2; i = ++m) {
	                    value = parsedItem[i];
	                    k = String(i);
	                    if (!data.hasOwnProperty(k)) {
	                      data[k] = value;
	                    }
	                  }
	                } else {
	                  for (key in parsedItem) {
	                    value = parsedItem[key];
	                    if (!data.hasOwnProperty(key)) {
	                      data[key] = value;
	                    }
	                  }
	                }
	              }
	            } else {
	              for (key in parsed) {
	                value = parsed[key];
	                if (!data.hasOwnProperty(key)) {
	                  data[key] = value;
	                }
	              }
	            }
	          }
	        } else if ((values.value != null) && (matches = this.PATTERN_ANCHOR_VALUE.exec(values.value))) {
	          isRef = matches.ref;
	          values.value = matches.value;
	        }
	        if (mergeNode) ; else if (!(values.value != null) || '' === Utils$3.trim(values.value, ' ') || Utils$3.ltrim(values.value, ' ').indexOf('#') === 0) {
	          if (!(this.isNextLineIndented()) && !(this.isNextLineUnIndentedCollection())) {
	            if (allowOverwrite || data[key] === void 0) {
	              data[key] = null;
	            }
	          } else {
	            c = this.getRealCurrentLineNb() + 1;
	            parser = new Parser(c);
	            parser.refs = this.refs;
	            val = parser.parse(this.getNextEmbedBlock(), exceptionOnInvalidType, objectDecoder);
	            if (allowOverwrite || data[key] === void 0) {
	              data[key] = val;
	            }
	          }
	        } else {
	          val = this.parseValue(values.value, exceptionOnInvalidType, objectDecoder);
	          if (allowOverwrite || data[key] === void 0) {
	            data[key] = val;
	          }
	        }
	      } else {
	        lineCount = this.lines.length;
	        if (1 === lineCount || (2 === lineCount && Utils$3.isEmpty(this.lines[1]))) {
	          try {
	            value = Inline$1.parse(this.lines[0], exceptionOnInvalidType, objectDecoder);
	          } catch (error) {
	            e = error;
	            e.parsedLine = this.getRealCurrentLineNb() + 1;
	            e.snippet = this.currentLine;
	            throw e;
	          }
	          if (typeof value === 'object') {
	            if (value instanceof Array) {
	              first = value[0];
	            } else {
	              for (key in value) {
	                first = value[key];
	                break;
	              }
	            }
	            if (typeof first === 'string' && first.indexOf('*') === 0) {
	              data = [];
	              for (n = 0, len3 = value.length; n < len3; n++) {
	                alias = value[n];
	                data.push(this.refs[alias.slice(1)]);
	              }
	              value = data;
	            }
	          }
	          return value;
	        } else if ((ref2 = Utils$3.ltrim(value).charAt(0)) === '[' || ref2 === '{') {
	          try {
	            return Inline$1.parse(value, exceptionOnInvalidType, objectDecoder);
	          } catch (error) {
	            e = error;
	            e.parsedLine = this.getRealCurrentLineNb() + 1;
	            e.snippet = this.currentLine;
	            throw e;
	          }
	        }
	        throw new ParseException$2('Unable to parse.', this.getRealCurrentLineNb() + 1, this.currentLine);
	      }
	      if (isRef) {
	        if (data instanceof Array) {
	          this.refs[isRef] = data[data.length - 1];
	        } else {
	          lastKey = null;
	          for (key in data) {
	            lastKey = key;
	          }
	          this.refs[isRef] = data[lastKey];
	        }
	      }
	    }
	    if (Utils$3.isEmpty(data)) {
	      return null;
	    } else {
	      return data;
	    }
	  };

	  Parser.prototype.getRealCurrentLineNb = function() {
	    return this.currentLineNb + this.offset;
	  };

	  Parser.prototype.getCurrentLineIndentation = function() {
	    return this.currentLine.length - Utils$3.ltrim(this.currentLine, ' ').length;
	  };

	  Parser.prototype.getNextEmbedBlock = function(indentation, includeUnindentedCollection) {
	    var data, indent, isItUnindentedCollection, newIndent, removeComments, removeCommentsPattern, unindentedEmbedBlock;
	    if (indentation == null) {
	      indentation = null;
	    }
	    if (includeUnindentedCollection == null) {
	      includeUnindentedCollection = false;
	    }
	    this.moveToNextLine();
	    if (indentation == null) {
	      newIndent = this.getCurrentLineIndentation();
	      unindentedEmbedBlock = this.isStringUnIndentedCollectionItem(this.currentLine);
	      if (!(this.isCurrentLineEmpty()) && 0 === newIndent && !unindentedEmbedBlock) {
	        throw new ParseException$2('Indentation problem.', this.getRealCurrentLineNb() + 1, this.currentLine);
	      }
	    } else {
	      newIndent = indentation;
	    }
	    data = [this.currentLine.slice(newIndent)];
	    if (!includeUnindentedCollection) {
	      isItUnindentedCollection = this.isStringUnIndentedCollectionItem(this.currentLine);
	    }
	    removeCommentsPattern = this.PATTERN_FOLDED_SCALAR_END;
	    removeComments = !removeCommentsPattern.test(this.currentLine);
	    while (this.moveToNextLine()) {
	      indent = this.getCurrentLineIndentation();
	      if (indent === newIndent) {
	        removeComments = !removeCommentsPattern.test(this.currentLine);
	      }
	      if (removeComments && this.isCurrentLineComment()) {
	        continue;
	      }
	      if (this.isCurrentLineBlank()) {
	        data.push(this.currentLine.slice(newIndent));
	        continue;
	      }
	      if (isItUnindentedCollection && !this.isStringUnIndentedCollectionItem(this.currentLine) && indent === newIndent) {
	        this.moveToPreviousLine();
	        break;
	      }
	      if (indent >= newIndent) {
	        data.push(this.currentLine.slice(newIndent));
	      } else if (Utils$3.ltrim(this.currentLine).charAt(0) === '#') ; else if (0 === indent) {
	        this.moveToPreviousLine();
	        break;
	      } else {
	        throw new ParseException$2('Indentation problem.', this.getRealCurrentLineNb() + 1, this.currentLine);
	      }
	    }
	    return data.join("\n");
	  };

	  Parser.prototype.moveToNextLine = function() {
	    if (this.currentLineNb >= this.lines.length - 1) {
	      return false;
	    }
	    this.currentLine = this.lines[++this.currentLineNb];
	    return true;
	  };

	  Parser.prototype.moveToPreviousLine = function() {
	    this.currentLine = this.lines[--this.currentLineNb];
	  };

	  Parser.prototype.parseValue = function(value, exceptionOnInvalidType, objectDecoder) {
	    var e, foldedIndent, matches, modifiers, pos, ref, ref1, val;
	    if (0 === value.indexOf('*')) {
	      pos = value.indexOf('#');
	      if (pos !== -1) {
	        value = value.substr(1, pos - 2);
	      } else {
	        value = value.slice(1);
	      }
	      if (this.refs[value] === void 0) {
	        throw new ParseException$2('Reference "' + value + '" does not exist.', this.currentLine);
	      }
	      return this.refs[value];
	    }
	    if (matches = this.PATTERN_FOLDED_SCALAR_ALL.exec(value)) {
	      modifiers = (ref = matches.modifiers) != null ? ref : '';
	      foldedIndent = Math.abs(parseInt(modifiers));
	      if (isNaN(foldedIndent)) {
	        foldedIndent = 0;
	      }
	      val = this.parseFoldedScalar(matches.separator, this.PATTERN_DECIMAL.replace(modifiers, ''), foldedIndent);
	      if (matches.type != null) {
	        Inline$1.configure(exceptionOnInvalidType, objectDecoder);
	        return Inline$1.parseScalar(matches.type + ' ' + val);
	      } else {
	        return val;
	      }
	    }
	    if ((ref1 = value.charAt(0)) === '[' || ref1 === '{' || ref1 === '"' || ref1 === "'") {
	      while (true) {
	        try {
	          return Inline$1.parse(value, exceptionOnInvalidType, objectDecoder);
	        } catch (error) {
	          e = error;
	          if (e instanceof ParseMore$2 && this.moveToNextLine()) {
	            value += "\n" + Utils$3.trim(this.currentLine, ' ');
	          } else {
	            e.parsedLine = this.getRealCurrentLineNb() + 1;
	            e.snippet = this.currentLine;
	            throw e;
	          }
	        }
	      }
	    } else {
	      if (this.isNextLineIndented()) {
	        value += "\n" + this.getNextEmbedBlock();
	      }
	      return Inline$1.parse(value, exceptionOnInvalidType, objectDecoder);
	    }
	  };

	  Parser.prototype.parseFoldedScalar = function(separator, indicator, indentation) {
	    var isCurrentLineBlank, j, len, line, matches, newText, notEOF, pattern, ref, text;
	    if (indicator == null) {
	      indicator = '';
	    }
	    if (indentation == null) {
	      indentation = 0;
	    }
	    notEOF = this.moveToNextLine();
	    if (!notEOF) {
	      return '';
	    }
	    isCurrentLineBlank = this.isCurrentLineBlank();
	    text = '';
	    while (notEOF && isCurrentLineBlank) {
	      if (notEOF = this.moveToNextLine()) {
	        text += "\n";
	        isCurrentLineBlank = this.isCurrentLineBlank();
	      }
	    }
	    if (0 === indentation) {
	      if (matches = this.PATTERN_INDENT_SPACES.exec(this.currentLine)) {
	        indentation = matches[0].length;
	      }
	    }
	    if (indentation > 0) {
	      pattern = this.PATTERN_FOLDED_SCALAR_BY_INDENTATION[indentation];
	      if (pattern == null) {
	        pattern = new Pattern$5('^ {' + indentation + '}(.*)$');
	        Parser.prototype.PATTERN_FOLDED_SCALAR_BY_INDENTATION[indentation] = pattern;
	      }
	      while (notEOF && (isCurrentLineBlank || (matches = pattern.exec(this.currentLine)))) {
	        if (isCurrentLineBlank) {
	          text += this.currentLine.slice(indentation);
	        } else {
	          text += matches[1];
	        }
	        if (notEOF = this.moveToNextLine()) {
	          text += "\n";
	          isCurrentLineBlank = this.isCurrentLineBlank();
	        }
	      }
	    } else if (notEOF) {
	      text += "\n";
	    }
	    if (notEOF) {
	      this.moveToPreviousLine();
	    }
	    if ('>' === separator) {
	      newText = '';
	      ref = text.split("\n");
	      for (j = 0, len = ref.length; j < len; j++) {
	        line = ref[j];
	        if (line.length === 0 || line.charAt(0) === ' ') {
	          newText = Utils$3.rtrim(newText, ' ') + line + "\n";
	        } else {
	          newText += line + ' ';
	        }
	      }
	      text = newText;
	    }
	    if ('+' !== indicator) {
	      text = Utils$3.rtrim(text);
	    }
	    if ('' === indicator) {
	      text = this.PATTERN_TRAILING_LINES.replace(text, "\n");
	    } else if ('-' === indicator) {
	      text = this.PATTERN_TRAILING_LINES.replace(text, '');
	    }
	    return text;
	  };

	  Parser.prototype.isNextLineIndented = function(ignoreComments) {
	    var EOF, currentIndentation, ret;
	    if (ignoreComments == null) {
	      ignoreComments = true;
	    }
	    currentIndentation = this.getCurrentLineIndentation();
	    EOF = !this.moveToNextLine();
	    if (ignoreComments) {
	      while (!EOF && this.isCurrentLineEmpty()) {
	        EOF = !this.moveToNextLine();
	      }
	    } else {
	      while (!EOF && this.isCurrentLineBlank()) {
	        EOF = !this.moveToNextLine();
	      }
	    }
	    if (EOF) {
	      return false;
	    }
	    ret = false;
	    if (this.getCurrentLineIndentation() > currentIndentation) {
	      ret = true;
	    }
	    this.moveToPreviousLine();
	    return ret;
	  };

	  Parser.prototype.isCurrentLineEmpty = function() {
	    var trimmedLine;
	    trimmedLine = Utils$3.trim(this.currentLine, ' ');
	    return trimmedLine.length === 0 || trimmedLine.charAt(0) === '#';
	  };

	  Parser.prototype.isCurrentLineBlank = function() {
	    return '' === Utils$3.trim(this.currentLine, ' ');
	  };

	  Parser.prototype.isCurrentLineComment = function() {
	    var ltrimmedLine;
	    ltrimmedLine = Utils$3.ltrim(this.currentLine, ' ');
	    return ltrimmedLine.charAt(0) === '#';
	  };

	  Parser.prototype.cleanup = function(value) {
	    var count, i, indent, j, l, len, len1, line, lines, ref, ref1, ref2, smallestIndent, trimmedValue;
	    if (value.indexOf("\r") !== -1) {
	      value = value.split("\r\n").join("\n").split("\r").join("\n");
	    }
	    count = 0;
	    ref = this.PATTERN_YAML_HEADER.replaceAll(value, ''), value = ref[0], count = ref[1];
	    this.offset += count;
	    ref1 = this.PATTERN_LEADING_COMMENTS.replaceAll(value, '', 1), trimmedValue = ref1[0], count = ref1[1];
	    if (count === 1) {
	      this.offset += Utils$3.subStrCount(value, "\n") - Utils$3.subStrCount(trimmedValue, "\n");
	      value = trimmedValue;
	    }
	    ref2 = this.PATTERN_DOCUMENT_MARKER_START.replaceAll(value, '', 1), trimmedValue = ref2[0], count = ref2[1];
	    if (count === 1) {
	      this.offset += Utils$3.subStrCount(value, "\n") - Utils$3.subStrCount(trimmedValue, "\n");
	      value = trimmedValue;
	      value = this.PATTERN_DOCUMENT_MARKER_END.replace(value, '');
	    }
	    lines = value.split("\n");
	    smallestIndent = -1;
	    for (j = 0, len = lines.length; j < len; j++) {
	      line = lines[j];
	      if (Utils$3.trim(line, ' ').length === 0) {
	        continue;
	      }
	      indent = line.length - Utils$3.ltrim(line).length;
	      if (smallestIndent === -1 || indent < smallestIndent) {
	        smallestIndent = indent;
	      }
	    }
	    if (smallestIndent > 0) {
	      for (i = l = 0, len1 = lines.length; l < len1; i = ++l) {
	        line = lines[i];
	        lines[i] = line.slice(smallestIndent);
	      }
	      value = lines.join("\n");
	    }
	    return value;
	  };

	  Parser.prototype.isNextLineUnIndentedCollection = function(currentIndentation) {
	    var notEOF, ret;
	    if (currentIndentation == null) {
	      currentIndentation = null;
	    }
	    if (currentIndentation == null) {
	      currentIndentation = this.getCurrentLineIndentation();
	    }
	    notEOF = this.moveToNextLine();
	    while (notEOF && this.isCurrentLineEmpty()) {
	      notEOF = this.moveToNextLine();
	    }
	    if (false === notEOF) {
	      return false;
	    }
	    ret = false;
	    if (this.getCurrentLineIndentation() === currentIndentation && this.isStringUnIndentedCollectionItem(this.currentLine)) {
	      ret = true;
	    }
	    this.moveToPreviousLine();
	    return ret;
	  };

	  Parser.prototype.isStringUnIndentedCollectionItem = function() {
	    return this.currentLine === '-' || this.currentLine.slice(0, 2) === '- ';
	  };

	  return Parser;

	})();

	var Parser_1 = Parser;

	// Generated by CoffeeScript 1.12.4
	var Dumper, Inline$2, Utils$4;

	Utils$4 = Utils_1;

	Inline$2 = Inline_1;

	Dumper = (function() {
	  function Dumper() {}

	  Dumper.indentation = 4;

	  Dumper.prototype.dump = function(input, inline, indent, exceptionOnInvalidType, objectEncoder) {
	    var i, key, len, output, prefix, value, willBeInlined;
	    if (inline == null) {
	      inline = 0;
	    }
	    if (indent == null) {
	      indent = 0;
	    }
	    if (exceptionOnInvalidType == null) {
	      exceptionOnInvalidType = false;
	    }
	    if (objectEncoder == null) {
	      objectEncoder = null;
	    }
	    output = '';
	    prefix = (indent ? Utils$4.strRepeat(' ', indent) : '');
	    if (inline <= 0 || typeof input !== 'object' || input instanceof Date || Utils$4.isEmpty(input)) {
	      output += prefix + Inline$2.dump(input, exceptionOnInvalidType, objectEncoder);
	    } else {
	      if (input instanceof Array) {
	        for (i = 0, len = input.length; i < len; i++) {
	          value = input[i];
	          willBeInlined = inline - 1 <= 0 || typeof value !== 'object' || Utils$4.isEmpty(value);
	          output += prefix + '-' + (willBeInlined ? ' ' : "\n") + this.dump(value, inline - 1, (willBeInlined ? 0 : indent + this.indentation), exceptionOnInvalidType, objectEncoder) + (willBeInlined ? "\n" : '');
	        }
	      } else {
	        for (key in input) {
	          value = input[key];
	          willBeInlined = inline - 1 <= 0 || typeof value !== 'object' || Utils$4.isEmpty(value);
	          output += prefix + Inline$2.dump(key, exceptionOnInvalidType, objectEncoder) + ':' + (willBeInlined ? ' ' : "\n") + this.dump(value, inline - 1, (willBeInlined ? 0 : indent + this.indentation), exceptionOnInvalidType, objectEncoder) + (willBeInlined ? "\n" : '');
	        }
	      }
	    }
	    return output;
	  };

	  return Dumper;

	})();

	var Dumper_1 = Dumper;

	// Generated by CoffeeScript 1.12.4
	var Dumper$1, Parser$1, Utils$5, Yaml;

	Parser$1 = Parser_1;

	Dumper$1 = Dumper_1;

	Utils$5 = Utils_1;

	Yaml = (function() {
	  function Yaml() {}

	  Yaml.parse = function(input, exceptionOnInvalidType, objectDecoder) {
	    if (exceptionOnInvalidType == null) {
	      exceptionOnInvalidType = false;
	    }
	    if (objectDecoder == null) {
	      objectDecoder = null;
	    }
	    return new Parser$1().parse(input, exceptionOnInvalidType, objectDecoder);
	  };

	  Yaml.parseFile = function(path, callback, exceptionOnInvalidType, objectDecoder) {
	    var input;
	    if (callback == null) {
	      callback = null;
	    }
	    if (exceptionOnInvalidType == null) {
	      exceptionOnInvalidType = false;
	    }
	    if (objectDecoder == null) {
	      objectDecoder = null;
	    }
	    if (callback != null) {
	      return Utils$5.getStringFromFile(path, (function(_this) {
	        return function(input) {
	          var result;
	          result = null;
	          if (input != null) {
	            result = _this.parse(input, exceptionOnInvalidType, objectDecoder);
	          }
	          callback(result);
	        };
	      })(this));
	    } else {
	      input = Utils$5.getStringFromFile(path);
	      if (input != null) {
	        return this.parse(input, exceptionOnInvalidType, objectDecoder);
	      }
	      return null;
	    }
	  };

	  Yaml.dump = function(input, inline, indent, exceptionOnInvalidType, objectEncoder) {
	    var yaml;
	    if (inline == null) {
	      inline = 2;
	    }
	    if (indent == null) {
	      indent = 4;
	    }
	    if (exceptionOnInvalidType == null) {
	      exceptionOnInvalidType = false;
	    }
	    if (objectEncoder == null) {
	      objectEncoder = null;
	    }
	    yaml = new Dumper$1();
	    yaml.indentation = indent;
	    return yaml.dump(input, inline, 0, exceptionOnInvalidType, objectEncoder);
	  };

	  Yaml.stringify = function(input, inline, indent, exceptionOnInvalidType, objectEncoder) {
	    return this.dump(input, inline, indent, exceptionOnInvalidType, objectEncoder);
	  };

	  Yaml.load = function(path, callback, exceptionOnInvalidType, objectDecoder) {
	    return this.parseFile(path, callback, exceptionOnInvalidType, objectDecoder);
	  };

	  return Yaml;

	})();

	if (typeof window !== "undefined" && window !== null) {
	  window.YAML = Yaml;
	}

	if (typeof window === "undefined" || window === null) {
	  commonjsGlobal.YAML = Yaml;
	}

	var Yaml_1 = Yaml;

	var yamljs = Yaml_1;

	exports.default = yamljs;

	Object.defineProperty(exports, '__esModule', { value: true });

});
