define("ember-svg-jar/inlined/lang-go", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g opacity=\".8\"><g fill=\"#40aaaf\"><path d=\"M49.7 37.6S47 35 45.8 33.8v-4.7c0-1.8-.1-3.4-.5-4.9 1.4 0 2.6-1.1 2.6-2.6 0-1.4-1.1-2.6-2.6-2.6-1 0-1.9.6-2.3 1.4-1.6-1.4-4.2-2.2-8.2-2.2-4.1 0-6.7.8-8.3 2.3-.4-.9-1.3-1.5-2.3-1.5-1.4 0-2.6 1.1-2.6 2.6 0 1.4 1.1 2.6 2.6 2.6h.2c-.4 1.5-.5 3.1-.5 4.9v4.6c-1.2 1.1-3.9 3.8-3.9 3.8-.7.7-1.1 1.4 0 2.6 1.1 1.1 1.9.7 2.6 0l1.3-1.3v4.3c0 3.9.5 7.4 3.6 9.3-.5 1.2-.9 2.2-.9 2.2-.4.9-.5 1.7 1 2.3 1.5.6 2-.1 2.4-1l.8-2.2s0-.1.1-.2c1.1.2 2.5.3 4 .3 1.8 0 3.3-.2 4.5-.5 0 .1.1.2.1.3l.8 2.2c.4.9.8 1.6 2.4 1 1.5-.6 1.4-1.4 1-2.3 0 0-.5-1.2-1-2.5 2.7-2 3.2-5.2 3.2-9v-4.3l1.3 1.3c.7.7 1.4 1.1 2.6 0s.6-1.7-.1-2.4zm-19.1-10c-1.7 0-3.2-1.4-3.2-3.2s1.4-3.2 3.2-3.2c1.7 0 3.2 1.4 3.2 3.2s-1.5 3.2-3.2 3.2zm6.1 4.6v1.7c0 .3-.3.6-.6.6h-.4c-.3 0-.6-.3-.6-.6v-2.1h-.5v2.1c0 .3-.3.6-.6.6h-.4c-.3 0-.6-.3-.6-.6v-1.7c-.7-.1-1.2-.8-1.2-1.5 0-.8.6-1.5 1.4-1.5V29c0-.6.8-1.1 1.7-1.1 1 0 1.7.5 1.7 1.1v.2c.8 0 1.5.7 1.5 1.5s-.6 1.5-1.4 1.5zm2.3-4.6c-1.7 0-3.2-1.4-3.2-3.2s1.4-3.2 3.2-3.2c1.7 0 3.2 1.4 3.2 3.2s-1.5 3.2-3.2 3.2z\"/><circle cx=\"39.1\" cy=\"24.3\" r=\"1.1\"/></g><circle cx=\"30.6\" cy=\"24.3\" r=\"1.1\" fill=\"#40aaaf\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 61.2 61.9"
    }
  };
  _exports.default = _default;
});