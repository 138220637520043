define("ember-svg-jar/inlined/avatar-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#2EB459\" d=\"M56.525 16.738c-4.473 0-8.109-3.637-8.109-8.105 0-4.472 3.639-8.109 8.109-8.109 4.469 0 8.104 3.638 8.104 8.109.001 4.468-3.635 8.105-8.104 8.105z\"/><path fill=\"#FFF\" d=\"M59.186 4.756l-3.312 5.201a.393.393 0 01-.541.121l-1.888-1.22a.389.389 0 00-.536.119l-.747 1.169a.397.397 0 00.117.541l2.953 1.921c.177.118.496.176.707.131l.444-.098c.21-.045.478-.227.593-.412l4.021-6.307a.4.4 0 00-.121-.543l-1.153-.739a.39.39 0 00-.537.116z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16.213",
      "height": "16.214",
      "viewBox": "48.416 0.524 16.213 16.214"
    }
  };
  _exports.default = _default;
});