define("ember-svg-jar/inlined/maintenance-hills-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<image overflow=\"visible\" opacity=\".1\" width=\"887\" height=\"370\" xlink:href=\"6887344C08643948.png\" transform=\"matrix(.24 0 0 .24 211.57 38.992)\"/><path fill=\"#8DAE7D\" d=\"M415.362 118.76l-85.881-70.768h-44.964L260.536 77.97h-13.988l-25.978 40.79z\"/><image overflow=\"visible\" opacity=\".1\" width=\"1173\" height=\"522\" xlink:href=\"6887344C08643949.png\" transform=\"scale(.24)\"/><path fill=\"#8DAE7D\" d=\"M6.48 118.76l40.165-77.64h27.311L96.598 6.48h69.28l45.643 57.64h33.615l29.876 54.64z\"/><path fill=\"#8DAE7D\" d=\"M415.362 118.76l-85.881-70.768h-44.964L260.536 77.97h-13.988l-25.978 40.79z\"/><path opacity=\".15\" fill=\"#49623D\" d=\"M314.24 62.971h-31.389l-43.984 54.141h19.777l32.591-39.758z\"/><path fill=\"#B6C8A9\" d=\"M329.481 47.992h-44.519l-1.117.841-3.942 4.927h47.401l77.83 65h10.228z\"/><linearGradient id=\"maintenance-hills-left_svg__a\" gradientUnits=\"userSpaceOnUse\" x1=\"328.72\" y1=\"115.26\" x2=\"328.72\" y2=\"118.76\"><stop offset=\"0\" stop-color=\"#345338\" stop-opacity=\"0\"/><stop offset=\"1\" stop-color=\"#345338\" stop-opacity=\".1\"/></linearGradient><path fill=\"url(#maintenance-hills-left_svg__a)\" d=\"M242.077 118.76h173.285l-4.247-3.5H242.077z\"/><path fill=\"#8DAE7D\" d=\"M415.362 118.76h-140.35l-29.876-54.64h-33.615L165.878 6.48h-69.28L73.956 41.12H46.645L6.48 118.76l-.001 5.005h408.883z\"/><path opacity=\".15\" fill=\"#49623D\" d=\"M90.996 30.779L80.207 49.96H53.822l-35.975 67.152h76.82l27.586-52.769H92.185l16.788-33.567z\"/><path fill=\"#B6C8A9\" d=\"M158.128 17.594l46.753 58.751h32.368l22.647 42.415h14.992l-29.752-54.64h-33.615L165.878 6.48h-69.28l-7.265 11.114z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "424.45",
      "height": "127.792",
      "viewBox": "0 0 424.45 127.792"
    }
  };
  _exports.default = _default;
});