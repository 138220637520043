define("ember-svg-jar/inlined/PR", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g opacity=\".4\"><path d=\"M88.3 26.1c0-2.1-1.7-3.8-3.8-3.8s-3.8 1.7-3.8 3.8c0 1.7 1.2 3.2 2.8 3.6v9.9c-1.6.4-2.8 1.9-2.8 3.6 0 2.1 1.7 3.8 3.8 3.8s3.8-1.7 3.8-3.8c0-1.7-1.2-3.2-2.8-3.6v-9.9c1.6-.4 2.8-1.9 2.8-3.6zm-5.6 0c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8zm3.6 17.2c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8zM99.3 39.6v-9.3c0-1.6-.5-2.8-1.4-3.7-1.5-1.4-3.6-1.3-3.6-1.3h-1.6l1.5-1.5c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-3.1 3.1c-.2.1-.3.3-.3.5-.1.1-.1.2-.1.3 0 .1 0 .2.1.3.1.2.2.4.4.5.4.5 1.3 1.4 3.1 3.1.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-1.5-1.5h1.6s1.4-.1 2.2.8c.5.5.8 1.3.8 2.3v9.3c-1.6.4-2.8 1.9-2.8 3.6 0 2.1 1.7 3.8 3.8 3.8s3.8-1.7 3.8-3.8c-.1-1.8-1.3-3.2-2.9-3.7zm-1 5.5c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8z\" fill=\"#010101\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 147.3 56.2"
    }
  };
  _exports.default = _default;
});