define("ember-svg-jar/inlined/icon-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"icon-flag_svg__st0\" d=\"M2.25.5v14m10.342-7.189L2.25 7.815V.5l10.342.505-3.027 2.9z\"/>",
    "attrs": {
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});