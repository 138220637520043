define("ember-svg-jar/inlined/customer-logos-moz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style/><path d=\"M56.812 10.403l8.055-8.492H48.281a.62.62 0 00-.595.587v1.713C46.139 1.703 43.104 0 39.614 0c-4.559 0-8.342 2.909-9.043 6.718V1.907H27.68a2.432 2.432 0 00-1.737.779l-5.595 6.218-5.591-6.218a2.423 2.423 0 00-1.74-.779h-2.885v12.412h3.373a.63.63 0 00.59-.602V7.892l6.252 6.964 6.241-6.964v5.825c.012.326.27.589.594.604h3.375V9.28c.702 3.81 4.483 6.72 9.044 6.72 5.061 0 9.165-3.581 9.165-8.001a7.083 7.083 0 00-.337-2.167h7.308l-8.032 8.492h16.569a.616.616 0 00.594-.588v-3.333zm-17.2 1.57c-2.512 0-4.55-1.779-4.55-3.974 0-2.194 2.037-3.975 4.55-3.975 2.516 0 4.554 1.781 4.554 3.975 0 2.195-2.038 3.974-4.554 3.974z\" fill=\"#4dbdeb\" bx:origin=\"0 0\"/>",
    "attrs": {
      "viewBox": "0 0 75 16",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:bx": "https://boxy-svg.com"
    }
  };
  _exports.default = _default;
});