define("ember-svg-jar/inlined/feature-icon-heroku", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.1 26.7c-.7 0-1.3-.3-1.8-.7-.5-.5-.7-1.1-.7-1.8V3.3c0-1.7.9-2.6 2.6-2.6h15.6c1.3 0 2.2.7 2.5 1.8 0 .2.1.4.1.7v21c0 1.5-1 2.5-2.5 2.5H6.1zm2.1-25h-2c-1.1 0-1.6.5-1.6 1.6v20.9c0 .4.2.8.4 1.1.3.3.6.4 1.1.4H21.9c1 0 1.5-.5 1.5-1.5v-21-.4c-.2-.7-.7-1.1-1.5-1.1H8.2z\" fill=\"#9b8cba\"/><path d=\"M15.7 7.8h2.5c.2 0 .4-.1.5-.2.8-1 1.4-2.1 2-3.2.1-.3.1-.4-.3-.4h-2.6c-.2 0-.4.1-.4.3-.5 1.2-1.1 2.3-1.9 3.3-.2.2-.1.2.2.2zM8.6 17.6c-.1-.1-.1-.2-.2-.1-.1 0-.1.2-.1.3v5.4c0 .1 0 .2.1.3.1.1.2-.1.3-.1l2.5-2.5c.2-.2.2-.4 0-.6-.9-1-1.8-1.9-2.6-2.7z\" fill=\"#9b8cba\"/><path d=\"M8.8 13.6c1.9-.7 3.8-1.4 5.9-1.7.5-.1 1-.1 1.5.1.4.1.6.4.6.8v10c0 .6 0 .6.6.6h1.8c.4 0 .5-.1.5-.5v-5.3-5.2c0-1.1-.4-2-1.3-2.5-.6-.4-1.3-.5-2.1-.6-1.5-.1-3 .3-4.5.7-.6.2-.6.2-.6-.4V4.4c0-.4-.1-.5-.5-.5H8.6c-.2 0-.4.1-.4.5v8.9c0 .5.1.5.6.3z\" fill=\"#9b8cba\"/>",
    "attrs": {
      "viewBox": "0 0 28 27.7"
    }
  };
  _exports.default = _default;
});