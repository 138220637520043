define("ember-svg-jar/inlined/github-signin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#FFF\" d=\"M7.999.25C3.721.25.25 3.808.25 8.197c0 3.51 2.218 6.489 5.302 7.54.384.072.528-.172.528-.383 0-.188-.009-.689-.009-1.352-2.157.479-2.612-1.066-2.612-1.066-.354-.918-.861-1.162-.861-1.162-.705-.492.052-.482.052-.482.779.057 1.189.82 1.189.82.688 1.211 1.812.861 2.255.66.067-.514.269-.865.491-1.064-1.722-.199-3.529-.881-3.529-3.926 0-.868.303-1.576.796-2.133-.081-.202-.344-1.01.077-2.105 0 0 .65-.212 2.129.815a7.234 7.234 0 013.883.001c1.479-1.027 2.127-.815 2.127-.815.424 1.094.159 1.902.078 2.103.495.558.796 1.266.796 2.133 0 3.051-1.812 3.725-3.538 3.92.279.248.524.73.524 1.473 0 1.062-.007 1.918-.007 2.18 0 .211.141.459.533.383 3.076-1.055 5.295-4.029 5.295-7.54C15.75 3.808 12.278.25 7.999.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});