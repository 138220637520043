define("ember-svg-jar/inlined/feature-icon-pullrequests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"none\" d=\"M12.6 5.8z\"/><circle cx=\"21.2\" cy=\"22.8\" r=\"1.3\" fill=\"none\"/><circle cx=\"7.4\" cy=\"22.8\" r=\"1.3\" fill=\"none\"/><path d=\"M7.4 2.4c-1.8 0-3.3 1.5-3.3 3.3 0 1.5 1 2.8 2.4 3.2l.4.1v10.7l-.4.1c-1.4.4-2.4 1.7-2.4 3.2 0 1.8 1.5 3.3 3.3 3.3s3.3-1.5 3.3-3.3c0-1.5-1-2.8-2.4-3.2l-.4-.1V8.9l.4-.1c1.4-.4 2.4-1.7 2.4-3.2-.1-1.8-1.5-3.2-3.3-3.2zm2.3 20.4c0 1.3-1 2.3-2.3 2.3s-2.3-1-2.3-2.3c0-1.3 1-2.3 2.3-2.3s2.3 1.1 2.3 2.3zM7.4 8C6.1 8 5.1 7 5.1 5.7c0-1.3 1-2.3 2.3-2.3s2.3 1 2.3 2.3C9.7 6.9 8.6 8 7.4 8zM12.7 6c0 .1.1.2.2.2l.1.1c.4.5 1.4 1.4 3.1 3.1.2.2.5.2.7 0 .2-.2.2-.5 0-.7l-2.4-2.4h2.9c.4 0 1.6.1 2.5.9.6.6 1 1.5 1 2.6v9.7l-.4.1C19 20 18 21.3 18 22.8c0 1.8 1.5 3.3 3.3 3.3s3.3-1.5 3.3-3.3c0-1.5-1-2.8-2.4-3.2l-.4-.1V9.9c0-1.4-.4-2.6-1.3-3.4-1.2-1.2-3-1.2-3.3-1.2h-2.8L16.6 3c.1-.1.2-.3.2-.4 0-.1-.1-.3-.1-.4s-.2-.1-.4-.1-.2.1-.3.2l-3.1 3.1-.1.1-.2.2v.1l.1.2zm10.8 16.8c0 1.3-1 2.3-2.3 2.3s-2.3-1-2.3-2.3c0-1.3 1-2.3 2.3-2.3s2.3 1.1 2.3 2.3z\" fill=\"#e5c025\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 28 27.7"
    }
  };
  _exports.default = _default;
});