define("ember-svg-jar/inlined/features-callouts-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#B8BABC\" d=\"M13.479 23.3V9.866a4.083 4.083 0 002.04-3.535 4.091 4.091 0 10-8.184 0 4.1 4.1 0 002.052 3.547v13.409a4.101 4.101 0 00-2.052 3.553 4.092 4.092 0 106.144-3.54zM11.426 4.273a2.06 2.06 0 012.057 2.062 2.054 2.054 0 01-2.057 2.055 2.054 2.054 0 01-2.058-2.055 2.06 2.06 0 012.058-2.062zm0 24.626a2.054 2.054 0 01-2.058-2.055 2.059 2.059 0 112.058 2.055z\"/><path fill=\"#B8BABC\" d=\"M29.852 23.286V10.358c0-6.452-5.854-6.061-5.854-6.061h-2.311V.183L15.56 6.331l6.128 6.133v-4.1h2.311c1.782 0 1.76 1.994 1.76 1.994v12.944a4.092 4.092 0 106.151 3.537 4.104 4.104 0 00-2.058-3.553zm-2.037 5.613a2.058 2.058 0 110-4.116 2.058 2.058 0 010 4.116z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "38.684",
      "height": "30.748",
      "viewBox": "0 0 38.684 30.748"
    }
  };
  _exports.default = _default;
});