define("ember-svg-jar/inlined/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 76.9Q0 56 10.3 38.35T38.25 10.4 76.8.1t38.55 10.3 27.95 27.95 10.3 38.55-10.3 38.55-27.95 27.95-38.55 10.3-38.55-10.3-27.95-27.95T0 76.9zM41.7 47q-1.5 2.4.8 4.2l13.2 10q.7.6 1.9.6 1.6 0 2.5-1.2 5.3-6.8 8.6-9.2 3.4-2.4 8.6-2.4 4.8 0 8.55 2.6t3.75 5.9q0 3.8-2 6.1t-6.8 4.5q-6.3 2.8-11.55 8.65T64 89.3v3.6q0 1.4.9 2.3t2.3.9h19.2q1.4 0 2.3-.9t.9-2.3q0-1.9 2.15-4.95T97.2 83q3.2-1.8 4.9-2.85t4.6-3.5 4.45-4.8 2.8-6.05 1.25-8.1q0-8.8-5.55-16.3T95.8 29.8t-17-4.1q-24.3 0-37.1 21.3zM64 124.9q0 1.4.9 2.3t2.3.9h19.2q1.4 0 2.3-.9t.9-2.3v-19.2q0-1.4-.9-2.3t-2.3-.9H67.2q-1.4 0-2.3.9t-.9 2.3v19.2z\" fill=\"#ccc\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "154",
      "width": "154",
      "viewBox": "0 0 154 154"
    }
  };
  _exports.default = _default;
});