define("ember-svg-jar/inlined/grey-gradient-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style/><linearGradient id=\"grey-gradient-circle_svg__grey-gradient-circle-merle\" gradientUnits=\"userSpaceOnUse\" x1=\"-223.793\" y1=\"-1602.12\" x2=\"-308.573\" y2=\"-1501.083\" gradientTransform=\"rotate(21.84 -4305.194 137.055)\"><stop offset=\"0\" stop-color=\"#fff\" stop-opacity=\"0\"/><stop offset=\".435\" stop-color=\"#d4d9df\" stop-opacity=\".435\"/><stop offset=\".788\" stop-color=\"#bdc6cf\" stop-opacity=\".788\"/><stop offset=\"1\" stop-color=\"#b4bfca\"/></linearGradient><circle cx=\"72.097\" cy=\"72.097\" r=\"65.946\" fill=\"url(#grey-gradient-circle_svg__grey-gradient-circle-merle)\"/>",
    "attrs": {
      "version": "1.1",
      "id": "grey-gradient-circle_svg__Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 145.314 146.061",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});