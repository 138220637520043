define("ember-svg-jar/inlined/features-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#3CA85B\" d=\"M19.864 3.064a.91.91 0 00-.265-1.231L17.023.137a.873.873 0 00-1.208.271L8.417 12.285a.863.863 0 01-1.206.269L3.005 9.77a.863.863 0 00-1.202.271L.14 12.707a.91.91 0 00.254 1.238l6.59 4.389c.406.271 1.118.401 1.59.295l.989-.221c.467-.098 1.065-.523 1.316-.94l8.985-14.404z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "18.666",
      "viewBox": "-0.001 0 20 18.666"
    }
  };
  _exports.default = _default;
});