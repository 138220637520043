define("ember-svg-jar/inlined/stage-canceled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\" d=\"M2.663 2.625l9.75 9.712\"/><circle cx=\"7.5\" cy=\"7.5\" r=\"7\" fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});