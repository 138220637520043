define("ember-svg-jar/inlined/icon-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"none\" stroke=\"#39aa56\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\" d=\"M13.36 8.02l-3.44 5.25-3.28-2.7\"/><circle cx=\"10\" cy=\"10.65\" r=\"7\" fill=\"none\" stroke=\"#39aa56\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 21.29"
    }
  };
  _exports.default = _default;
});