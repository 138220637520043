define("ember-svg-jar/inlined/icon-shared", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" stroke=\"#9ca1a6\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><circle cx=\"13.898\" cy=\"3.853\" r=\"2.098\"/><circle cx=\"4.022\" cy=\"8.867\" r=\"2.098\"/><circle cx=\"13.898\" cy=\"13.766\" r=\"2.098\"/><path d=\"M11.898 4.718L6.12 8.134M11.585 13.454L6.12 10.35\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 19.525 17.526"
    }
  };
  _exports.default = _default;
});