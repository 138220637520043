define("ember-svg-jar/inlined/lang-perl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M55.6 30.6c-1-3.2-3.2-7.2-5-8.9-.1-.1-.1-.1-.2-.1-1.2-1-2.7-1.6-4.3-1.6-3.1 0-5.8 2.2-6.5 5.1-.7-2.9-3.3-5.1-6.5-5.1-2.7 0-5.1 2.2-6.6 4.9-.5 1-1.2 3.8-3.3 4.4-1.8.5-3.7-.3-4.1-2.2-.4-2.3-.5-4.7.3-7 .3-.9.2-1.6-.9-1.5-.9.1-1.6-.2-2.3-.5-1.2-.5-2.5-.7-3.6.1-1.5 1-3.1 1.5-4.9 1.8-1.6.3-2.9 1.1-3.5 2.8-.6 1.8.3 3 2.1 3 .2 0 .5 0 .7-.1 3.8-1.2 5.5.6 6.4 4 1.2 4.4 3.4 7.8 8 9.5 2.4.9 3.2 3.4 4.1 5.6.7 1.7 1.2 3.4 1.8 5.1.3.9.8 1.9 1.9 1.7 1.2-.2 1.3-1.2 1.3-2.2.1-1.9.4-3.9 1.1-5.7.3-.8.7-1.5 1.3-2.1 2.6-2.4 10.1-2.7 12.8-.5.8.6 1.2 1.4 1.4 2.4.3 1.9.7 3.8 1 5.7.2 1 .1 2.2 1.4 2.3 1.3.1 1.6-1.1 1.9-2 1.6-4.3 3-8.6 4-13.1.4-.6.9-3.4.2-5.8z\" opacity=\".8\" fill=\"#39467f\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 61.2 61.9"
    }
  };
  _exports.default = _default;
});