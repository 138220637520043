define("ember-svg-jar/inlined/icon-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle class=\"icon-clock_svg__st0\" cx=\"7.5\" cy=\"7.5\" r=\"7\"/><path class=\"icon-clock_svg__st1\" d=\"M7.275 3.208V7.5L9.98 9.254\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});