define("ember-svg-jar/inlined/push-icon-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#EDEEEE\" d=\"M32.748 65.249C14.83 65.249.253 50.67.253 32.75S14.83.251 32.748.251c17.92 0 32.499 14.579 32.499 32.499S50.668 65.249 32.748 65.249zm0-61.998c-16.264 0-29.495 13.233-29.495 29.499s13.231 29.499 29.495 29.499c16.266 0 29.499-13.232 29.499-29.499 0-16.266-13.233-29.499-29.499-29.499z\"/><path fill=\"none\" d=\"M28.913 31.727l1.948 5.801 5.971-2-1.994-5.829z\"/><path fill=\"#E9A722\" d=\"M39.725 43.991a2.874 2.874 0 003.65 1.791 2.878 2.878 0 001.794-3.654l-1.026-2.986-5.448 1.828 1.03 3.021zM37.399 19.406a2.873 2.873 0 00-3.651-1.788 2.872 2.872 0 00-1.79 3.652l1.021 2.986 5.441-1.861-1.021-2.989zM36.832 35.528l5.45-1.828-2-5.863-5.444 1.862z\"/><path fill=\"#3EB791\" d=\"M28.259 47.856a2.873 2.873 0 003.646 1.815 2.876 2.876 0 001.809-3.643l-1.021-3.047-5.454 1.832 1.02 3.043zM26.09 23.308a2.874 2.874 0 10-5.45 1.831l1 2.992 5.443-1.86-.993-2.963zM28.913 31.727l-5.44 1.857 1.934 5.774 5.454-1.83z\"/><path fill=\"#6EC8DB\" d=\"M43.253 26.824a2.876 2.876 0 001.792-3.653 2.874 2.874 0 00-3.65-1.793l-2.975 1.017 1.862 5.442 2.971-1.013zM34.838 29.699l-1.859-5.443-5.896 2.015 1.83 5.456zM18.675 29.143a2.884 2.884 0 00-1.796 3.657 2.883 2.883 0 003.654 1.791l2.94-1.006-1.833-5.454-2.965 1.012z\"/><path fill=\"#658439\" d=\"M38.421 22.394l1.863 5.442-5.441 1.862-1.863-5.442z\"/><path fill=\"#1A927C\" d=\"M27.083 26.271l-5.443 1.86 1.833 5.453 5.44-1.857z\"/><path fill=\"#DF1864\" d=\"M48.938 34.5a2.89 2.89 0 00-3.644-1.815L42.282 33.7l1.859 5.441 2.983-1a2.884 2.884 0 001.814-3.641zM22.569 40.309a2.879 2.879 0 001.833 5.456l2.836-.951-1.831-5.455-2.838.95zM30.861 37.528l1.831 5.453 6.001-2.012-1.861-5.441z\"/><path fill=\"#CB2027\" d=\"M36.832 35.528l1.861 5.441 5.449-1.828-1.86-5.441z\"/><path fill=\"#351138\" d=\"M27.238 44.813l-1.831-5.455 5.455-1.83 1.83 5.454z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "65.5",
      "height": "65.5",
      "viewBox": "0 0 65.5 65.5"
    }
  };
  _exports.default = _default;
});