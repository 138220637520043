define("ember-svg-jar/inlined/icon-github", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"icon-github_svg__st0\" d=\"M10.384 11.324c0-.408-.06-.807-.243-1.179a1.53 1.53 0 00-.33-.467l-.024-.028.283-.031a5.061 5.061 0 002.098-.716c.318-.193.618-.409.848-.706.275-.355.456-.757.58-1.188.124-.43.186-.87.221-1.315.021-.265.031-.529.002-.795-.083-.743-.35-1.405-.853-1.964-.034-.038-.042-.066-.02-.115.08-.175.095-.366.126-.553.1-.597-.039-1.162-.229-1.72-.013-.038-.039-.046-.071-.045-.131.008-.263.015-.394.026-.326.029-.628.14-.912.293-.352.19-.696.397-1.044.595a.12.12 0 01-.079.011 8.61 8.61 0 00-1.504-.254 21.078 21.078 0 00-1.931-.009 8.08 8.08 0 00-1.619.264c-.038.01-.064.003-.096-.017A9.663 9.663 0 003.929.712C3.599.562 3.25.517 2.894.501c-.092-.004-.095 0-.124.085-.122.365-.22.734-.245 1.12-.018.293.025.58.083.865.018.092.05.183.082.271.013.034.004.051-.015.076-.104.132-.213.262-.309.4-.294.419-.448.892-.534 1.392-.07.409-.043.819-.001 1.228.044.431.121.854.258 1.266.129.39.312.751.576 1.067.201.24.45.424.714.59a4.97 4.97 0 001.704.683c.226.044.752.105.752.105s-.186 1.31-.674 1.589c-.538.307-1.365.371-1.929-.192-.202-.202-.696-1.154-1.385-1.411-.879-.327-1.044.816-.628 1.399.261.367.625.761.77 1.078.404.886 1.056 1.281 1.521 1.416.619.18 1.688-.016 1.688-.016l.06.989 5.112-.006c-.002-.001.014-2.368.014-3.171z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});