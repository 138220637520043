define("ember-svg-jar/inlined/icon-fingerprint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" stroke=\"#9d9d9d\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M3.77 12.558c2.211-2.398.293-6.608 3.312-7.219 4.268-.864 2.615 6.519 2.187 8.021\"/><path d=\"M11.991 12.382c.106-.722 1.244-10.109-4.858-9.243-4.602.653-3.97 5.944-5.137 8.041\"/><path d=\"M.509 9.345s-.55-7.602 6.821-8.304c7.413-.706 7.224 7.857 7.152 9.057m-6.89-2.082s.357 3.011-1.247 5.423\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});